import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Slide } from "react-awesome-reveal";
import toast from "react-hot-toast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FaUser } from "react-icons/fa";
import { LuScrollText } from "react-icons/lu";
import { AiOutlineMessage } from "react-icons/ai";
import { TiArrowSortedDown } from "react-icons/ti";
import { IoCloseCircle } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import {
  MdOutlineAppShortcut,
  MdOutlineContentCopy,
  MdOutlineToken,
} from "react-icons/md";
import SubPagesLayout from "../../layout/subPagesLayout";
import SecondaryButton from "../../components/common/buttons/SecondaryButton";
import CardTitle from "../../components/common/title/CardTitle";
import { AccountHeader } from "../../components/auth/accountHeader";
import { RootState } from "../../features/store";
import { logout } from "../../api/auth";
import { generateCode } from "../../features/profile/profiletSlice";
import { fetchPinnedCoins } from "../../features/crypto/cryptosSlice";
import { fetchWalletsList } from "../../features/assetsManagement/assetsManagementSlice";
import useAuth from "../../utils/useAuth";
import styles from "./account.module.scss";

const Account = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isAuthValid = useAuth();
  const userInfo: any = useSelector((state: RootState) => state.account);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );
  const { generatedCode, generatedCodeLoading } = useSelector(
    (state: RootState) => state.profile
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [userFullname, setUserFullname] = useState<string | null>(null);
  const [isCopyText, setCopyText] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthValid) {
      //@ts-ignore
      if (!cryptosState.pinnedCoins) dispatch(fetchPinnedCoins());

      if (!assetsManagementState.wallets) {
        //@ts-ignore
        dispatch(fetchWalletsList());
      }
    }
  }, [isAuthValid]);

  useEffect(() => {
    setUserFullname(
      `${userInfo.data?.user.first_name ?? ""} ${userInfo.data?.user.last_name ?? ""}`
    );
  }, [userInfo]);

  const signoutHandler = async () => {
    setLoading(true);
    const response = await logout();
    if (response.status === 200) {
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      setLoading(false);
      window.location.replace("/");
    }
  };

  const profileItems = [
    {
      id: 1,
      title: "Profile",
      icon: (
        <FaUser
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 900,
      link: "/account/profile",
    },
    {
      id: 3,
      title: "Account management",
      icon: (
        <MdOutlineAppShortcut
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1100,
      link: "/account/management",
    },
    {
      id: 4,
      title: "Terms & Conditions",
      icon: (
        <LuScrollText
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1200,
      link: "/account/terms-and-conditions",
    },
    {
      id: 5,
      title: "FAQ",
      icon: (
        <AiOutlineMessage
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1300,
      link: "/account/FAQ",
    },
  ];

  const handleShowCode = () => {
    //@ts-ignore
    dispatch(generateCode());
  };

  const handleCopyCode = () => {
    setCopyText(true);
    const text: any = document.getElementById("early-access-code");
    navigator.clipboard.writeText(text?.innerHTML);
    toast.success("Early access code is copied to your clipboard.");
    setTimeout(() => {
      setCopyText(false);
    }, 5000);
  };

  return (
    <SubPagesLayout title="Coinfident | Account">
      <Fade cascade damping={0.3}>
        <Box className={styles.accountContent}>
          <Box className={styles.accountProfile}>
            <CardTitle isCenter>Account</CardTitle>
            <AccountHeader />
          </Box>
          {hasSubscription && (
            <Box
              className={styles.accountPlan}
              sx={{
                borderBottom: `1px solid ${
                  theme.palette.mode === "light"
                    ? //@ts-ignore
                      theme.palette.grey.main
                    : //@ts-ignore
                      theme.palette.grey.dark
                }`,
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? //@ts-ignore
                          theme.palette.grey.dark
                        : //@ts-ignore
                          theme.palette.grey["700"],
                  }}
                >
                  Your Plan
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    //@ts-ignore
                    color: theme.palette.common.greenText,
                  }}
                >
                  Yearly
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? //@ts-ignore
                          theme.palette.grey.dark
                        : //@ts-ignore
                          theme.palette.grey["700"],
                  }}
                >
                  Renews
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? //@ts-ignore
                          theme.palette.grey.dark
                        : //@ts-ignore
                          theme.palette.grey["400"],
                  }}
                >
                  Mar 8, 2025
                </Typography>
              </Box>
            </Box>
          )}
          <ul
            className="account-nav"
            style={{ marginTop: hasSubscription ? 0 : "3rem" }}
          >
            {profileItems.map((ProfileItem) => (
              <li key={ProfileItem.id}>
                <Slide direction="down" duration={ProfileItem.duration}>
                  <Link
                    to={ProfileItem.link}
                    color={theme.palette.text.primary}
                  >
                    {ProfileItem.icon}
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      {ProfileItem.title}
                    </Typography>
                  </Link>
                </Slide>
              </li>
            ))}

            <Accordion>
              <AccordionSummary expandIcon={<TiArrowSortedDown />}>
                <Stack direction="row">
                  <MdOutlineToken
                    size={24}
                    //@ts-ignore
                    color={theme.palette.common.icon}
                  />
                  <Typography mx={2} color={theme.palette.text.primary}>
                    Early Access Code
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" alignItems="center">
                  {assetsManagementState.walletsLoading ? (
                    <CircularProgress size={20} />
                  ) : assetsManagementState.wallets.length == 0 ? (
                    <IoCloseCircle size={20} />
                  ) : (
                    <IoIosCheckmarkCircle size={20} />
                  )}
                  <Typography
                    ml={1}
                    color={theme.palette.text.primary}
                    variant="body2"
                  >
                    A wallet connected in asset tracking
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" mt={1}>
                  {cryptosState.pinnedCoinsLoading ? (
                    <CircularProgress size={20} />
                  ) : cryptosState.pinnedCoins.length == 0 ? (
                    <IoCloseCircle size={20} />
                  ) : (
                    <IoIosCheckmarkCircle size={20} />
                  )}
                  <Typography
                    ml={1}
                    color={theme.palette.text.primary}
                    variant="body2"
                  >
                    A watch list was created
                  </Typography>
                </Stack>
                <SecondaryButton
                  text="Generate/Show code"
                  clickHandler={handleShowCode}
                  loading={generatedCodeLoading}
                  disabled={
                    cryptosState.pinnedCoins?.length == 0 ||
                    assetsManagementState.wallets?.length == 0
                  }
                />
                {generatedCode && (
                  <Stack direction="row" justifyContent="center" mt={2}>
                    <Typography
                      id="early-access-code"
                      variant="h5"
                      mr={2}
                      color={theme.palette.text.primary}
                    >
                      {generatedCode}
                    </Typography>
                    <IconButton onClick={handleCopyCode}>
                      {isCopyText ? (
                        <IoIosCheckmarkCircle size={20} />
                      ) : (
                        <MdOutlineContentCopy size={20} />
                      )}
                    </IconButton>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>
          </ul>
        </Box>
      </Fade>
      <SecondaryButton
        text="Sign Out"
        clickHandler={signoutHandler}
        loading={loading}
      />
    </SubPagesLayout>
  );
};

export default Account;
