import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { LineChartProps } from "./interfaces";
import styles from "./charts.module.scss";

const LineChart: React.FC<LineChartProps> = ({
  data,
  name,
  color,
  showAxis = false,
  height = 120,
  legend_offset = 0,
}) => {
  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });
  const theme = useTheme();
  useEffect(() => {
    console.log(color);

    setState({
      series: [
        {
          name,
          data,
        },
      ],
      options: {
        colors: color ?? [theme.palette.primary.main],
        chart: {
          parentHeightOffset: 0,
          type: "line",
          fontFamily: "Poppins",
          toolbar: { show: false },
          zoom: { enabled: false },
          animations: {
            easing: "easeout",
          },
        },
        tooltip: {
          //@ts-ignore
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class='${
              styles.linechart_tooltip
            }' style="background: ${
              //@ts-ignore
              theme.palette.common.solidCard
            }">
              <h6 style="color: ${theme.palette.common.white} ">${
                w.globals.categoryLabels[dataPointIndex]
              }</h6>
              <div>
                <h4 style="color: ${theme.palette.common.white} ">${
                  w.globals.seriesNames[0]
                }:</h4>
                <h5 style="color: ${theme.palette.common.white} ">${
                  series[seriesIndex][dataPointIndex]
                }</h5>
              </div>
              </div>`;
          },
        },
        grid: { show: false },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
          horizontalAlign: "left",
          floating: true,
          offsetY: legend_offset,
          markers: {
            width: 8,
            height: 8,
          },
          labels: {
            useSeriesColors: true,
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          tickAmount: 8,
          labels: {
            show: showAxis,
            rotate: 0,
            offsetY: 0,
            offsetX: 0,
            style: {
              //@ts-ignore
              colors: theme.palette.common.whiteToGreen,
              fontWeight: 100,
            },
          },
          axisBorder: { show: showAxis },
          axisTicks: { show: showAxis },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            show: showAxis,
            style: {
              //@ts-ignore
              colors: theme.palette.common.whiteToGreen,
              fontWeight: 100,
            },
          },
          axisBorder: { show: showAxis },
          axisTicks: { show: showAxis },
        },
      },
    });
  }, [data, color]);

  return (
    <Box id="influencerChart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        height={`${height}px`}
        width="100%"
      />
    </Box>
  );
};

export default LineChart;
