import { useSelector } from "react-redux";
import moment from "moment";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Guage from "../../../guage";
import NewsCard from "../../../common/newsCard";
import Progressbar from "../../../common/progressbar";
import CardTitle from "../../../common/title/CardTitle";
import { CryptoSignalItem, CryptoSignalTabProps } from "../../interfaces";
import { RootState } from "../../../../features/store";
import Loading from "../../../common/loading";
import SpsLineChart from "../../../charts/SpsLineChart";
import styles from "./signalTab.module.scss";
import { useMemo } from "react";
import { calculateGuageValue } from "../../../../utils/functions";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

type SignalData = {
  date: string;
  count_sig_0: number;
  count_sig_1: number;
};

type PriceData = {
  date: string;
  avg_price: number;
  coin: number;
};

const emotionalSentimentInfo = `The Emotional Sentiment part is different from SPS (social prediction signal).
This section discusses whether the text of the tweets published by the influencers was happier or sadder, and this is not necessarily related to the influencers' opinions about buying or not buying.`;

const spsInfo = `SPS (Social Prediction Signal) is an index based on just crypto influencers and news outlet’s hourly crypto-related tweets on X platform and proprietary AI Models to provide accurate “buy” and “not buy” signals for each coin. The accuracy of this signal is currently 86%.`;
const spsHistoryInfo = `The bar chart below represents the Social Prediction Signal (SPS) calculated for this coin over the last 4 days. The line chart shows the coin's price during the same period.`;

const CryptoSignalTab: React.FC<CryptoSignalTabProps> = ({
  guageValue,
  cryptoSignalData,
  fetchNextPage,
  isAvailable,
  signalCount,
  signalCountLoading,
  selectedCommunity,
}) => {
  const theme = useTheme();
  const params = useParams();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const signalState: any = useSelector((state: RootState) => state.signal);

  const influencerSignal = useSelector((state: RootState) => state.signal);
  const selectedCoin = cryptosState?.coinsStatistics?.find(
    (item: any) => item.full_id === params.crypto
  );

  console.log(signalState.cryptoSignalCount);
  const postsCountData = useMemo(() => {
    if (!isEmpty(signalState.cryptoSignalCount)) {
      const allSignals = signalState.cryptoSignalCount[
        selectedCoin.coin_id
      ]?.map((signal: any) => {
        const yValue = calculateGuageValue(
          signal.zero_signals ?? 0,
          signal.one_signals ?? 0,
          0
        );
        return {
          x: signal.datetime,
          y: isNaN(yValue) ? 0 : yValue,
        };
      });

      const sortData = allSignals?.sort(
        (a: any, b: any) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );

      return sortData;
    }
  }, [signalState.cryptoSignalCount]);

  const pricesData = useMemo(() => {
    if (signalState.cryptoSignalCount) {
      const allPrices = signalState?.cryptoSignalCount[
        selectedCoin.coin_id
      ]?.map((price: any) => ({
        x: price.datetime,
        y: +price.avg_price.toFixed(1),
        normalize: +price.avg_price.toFixed(1),
      }));
      const sortData = allPrices?.sort(
        (a: any, b: any) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );
      return sortData;
    }
  }, [signalState.cryptoSignalCount]);
  function filterData(postsCountData: any, pricesData: any) {
    const priceDates = new Set(pricesData.map((item: any) => item.x));
    const filteredPostsCountData = postsCountData.filter((post: any) =>
      priceDates.has(post.x)
    );
    return filteredPostsCountData;
  }

  return (
    <Box
      sx={{
        height: "98%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        position: "relative",
      }}
    >
      <CardTitle isCenter hasTooltip tooltipInfo={spsInfo}>
        SPS
      </CardTitle>
      <Typography
        textAlign="center"
        variant="caption"
        color={theme.palette.text.primary}
      >
        (Social Prediction Signal)
      </Typography>
      <Box
        sx={{
          maxWidth: "300px",
          margin: "-.5rem auto 3rem",
        }}
      >
        <Guage
          value={guageValue}
          type="technicalSignal"
          isEmpty={isAvailable == undefined}
        />
      </Box>
      {signalCountLoading ? (
        <Loading />
      ) : isEmpty(postsCountData) || isEmpty(pricesData) ? (
        <>
          <CardTitle isCenter hasTooltip tooltipInfo={spsHistoryInfo}>
            SPS History
          </CardTitle>
          <Typography
            align="center"
            my={5}
            className="default-card-style"
            sx={{
              background: (theme: any) => theme.palette.background.paper,
              mt: 2,
            }}
          >
            <strong>N/A</strong> (Coming Soon)
          </Typography>
        </>
      ) : (
        <Box
          sx={{
            width: "75%",
            margin: "0 auto 3rem",
          }}
        >
          <CardTitle isCenter hasTooltip tooltipInfo={spsHistoryInfo}>
            SPS History
          </CardTitle>
          <SpsLineChart
            name="SPS HISTORY"
            data={[
              {
                name: "SPS",
                type: "line",
                data: filterData(postsCountData, pricesData),
              },
              {
                name: "Price",
                type: "line",
                data: pricesData,
              },
            ]}
          />
        </Box>
      )}
      <CardTitle isCenter hasTooltip tooltipInfo={emotionalSentimentInfo}>
        Emotional Sentiment
      </CardTitle>
      {cryptoSignalData.results && (
        <Box>
          {isAvailable == undefined ? (
            <Typography
              align="center"
              mt={5}
              className="default-card-style"
              sx={{
                background: (theme: any) => theme.palette.background.paper,
                mt: 2,
              }}
            >
              <strong>N/A</strong> (Coming Soon)
            </Typography>
          ) : (
            <Progressbar
              negative={
                cryptoSignalData.results.sentiment &&
                cryptoSignalData.results.sentiment[0]
              }
              neutral={
                cryptoSignalData.results.sentiment &&
                cryptoSignalData.results.sentiment[1]
              }
              positive={
                cryptoSignalData.results.sentiment &&
                cryptoSignalData.results.sentiment[2]
              }
            />
          )}
        </Box>
      )}

      {cryptoSignalData.results && (
        <>
          <Box mt={7} mb={1}>
            <CardTitle isCenter>Influencers Tweets</CardTitle>
          </Box>
          {cryptoSignalData.results.relevant_tweets !== undefined &&
          cryptoSignalData.results.relevant_tweets?.length > 0 ? (
            <Box
              className={`${styles.cryptoTweets} default-card-style customScrollbar`}
              sx={{ padding: downLg ? 0 : "1.5rem" }}
            >
              {cryptoSignalData.results.relevant_tweets?.map(
                (cryptoSignal: CryptoSignalItem, idx: number) => (
                  <NewsCard
                    key={idx}
                    screen_name={cryptoSignal.screen_name}
                    id={cryptoSignal.user_id_str}
                    name={cryptoSignal.screen_name}
                    media={cryptoSignal.media}
                    date={`${moment
                      .parseZone(cryptoSignal.created_at)
                      .format("DD MMMM YYYY")}`}
                    content={cryptoSignal.full_text}
                    reply_count={
                      cryptoSignal.reply_count ? cryptoSignal.reply_count : 0
                    }
                    favorite_count={
                      cryptoSignal.favorite_count
                        ? cryptoSignal.favorite_count
                        : 0
                    }
                    retweet_count={
                      cryptoSignal.retweet_count
                        ? cryptoSignal.retweet_count
                        : 0
                    }
                  />
                )
              )}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {influencerSignal.cyptoPageInfluencerSignalLoading ? (
                  <Loading />
                ) : (
                  <Button size="small" variant="text" onClick={fetchNextPage}>
                    View more
                  </Button>
                )}
              </Box>
            </Box>
          ) : (
            <Typography
              align="center"
              mt={5}
              className="default-card-style"
              sx={{
                background: theme.palette.background.paper,
                mt: 2,
              }}
            >
              <strong>N/A</strong> (Coming Soon)
            </Typography>
          )}
        </>
      )}

      {cryptoSignalData.results.relevant_tweets?.length < 1 &&
      Object.keys(cryptoSignalData.results.signal).length < 2 &&
      Object.keys(cryptoSignalData.results.sentiment).length < 2 ? (
        <Box //@ts-ignore
          sx={{ background: theme.palette.common.card }}
          p={3}
          className="default-card-style"
        >
          <Typography align="center" color={theme.palette.text.primary}>
            Couldn't find any data
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default CryptoSignalTab;
