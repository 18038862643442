import ApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMediaQuery, useTheme } from "@mui/material";

type ChartData = {
  x: string; // The x-axis value (e.g., "Jan")
  y: number | [number, number]; // A single number (for line) or a range (for rangeBar)
};

type Series = {
  name: string; // Name of the series
  type: "rangeBar" | "line"; // Type of the chart for this series
  data: ChartData[]; // Array of data points
};
type LineBarChartProps = {
  series: Series[];
};

export const LineBarChartAgent = ({ series }: LineBarChartProps) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const chartOption: ApexOptions = {
    chart: {
      type: "rangeBar",
      toolbar: { show: true },
    },
    markers: {
      size: 4,
      colors: ["#fff"],
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
      },
    },
    xaxis: {
      type: "category",
      categories: ["Jan", "Feb", "Mar", "Apr", "May"],
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: "rast",
        },
        seriesName: "Temperature Range",
      },
      {
        opposite: false,
        title: {
          text: "chap",
        },
      },
    ],
  };

  return (
    <div>
      <ApexChart
        options={chartOption}
        series={series}
        type="rangeBar"
        height={300}
        width={downLg ? 350 : 750}
      />
    </div>
  );
};