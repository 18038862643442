import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import { PrimaryButtonProps } from "../interfaces";
import styles from "./buttons.module.scss";

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  linkUrl,
  disabled,
  loading,
  isFullWidth,
  startIcon,
  size,
  customstyle,
  clickHandler,
}) => {
  const theme = useTheme();

  if (linkUrl) {
    return (
      <Link
        to={linkUrl}
        className={styles.primary_button}
        style={{
          //@ts-ignore
          color: theme.palette.common.greenToWhite,
          background: theme.palette.primary.main,
          width: isFullWidth ? "100%" : "auto",
          textAlign: "center",

          ...customstyle,
        }}
      >
        {text}
      </Link>
    );
  }
  return (
    <LoadingButton
      fullWidth={isFullWidth ? true : false}
      type={!!clickHandler ? "button" : "submit"}
      variant="contained"
      loading={loading}
      disabled={disabled}
      startIcon={startIcon}
      loadingPosition="center"
      className={styles.primary_button}
      //@ts-ignore
      size={size ? size : "medium"}
      onClick={clickHandler}
      sx={{
        //@ts-ignore
        color: theme.palette.common.greenToWhite,
        background: theme.palette.primary.main,
        "&:hover": {
          background: theme.palette.secondary.dark,
        },
        padding: "10px",
        ...customstyle,
      }}
    >
      {text}
    </LoadingButton>
  );
};

export default PrimaryButton;
