import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import useScrollOnDrag from "react-scroll-ondrag";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { RiPushpin2Line, RiPushpin2Fill } from "react-icons/ri";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import Avatar from "../avatar";
import TableTitle from "../title/TableTitle";
import StatisticsGuage from "../../guage/StatisticsGuage";
import SmallAreaChart from "../../charts/SmallAreaChart";
import {
  calculateDayPercentChange,
  calculateGuageValue,
  calculateWeekPercentChange,
  formatNumber,
  formatPrice,
  getCoinImage,
  sortTableData,
} from "../../../utils/functions";
import { pinCryptoHandler, unpinCryptoHandler } from "../../../api/crypto";
import { fetchPinnedCoins } from "../../../features/crypto/cryptosSlice";
import LoginAlertModal from "../loginAlertModal";
import DefaultTooltip from "../tooltip";
import styles from "./assetsTable.module.scss";

const tabelHeaderData = [
  {
    title: "Assets",
    hiddenFor: [],
    isSticky: true,
    info: null,
  },
  // { title: "Price", hiddenFor: ["shareOfVoice"], isSticky: false, info: null },
  {
    title: "SPS\n(Social Prediction Signal)",
    hiddenFor: [],
    isSticky: false,
    info: "SPS (Social Prediction Signal) is an index based on just crypto influencers and news outlet’s hourly crypto-related tweets on X platform and proprietary AI Models to provide accurate “buy” and “not buy” signals for each coin. The accuracy of this signal is currently 86%.",
  },
  {
    title: "Top Influencers",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: "Active crypto influencers and news outlets on X platform with over 5000 followers, known for their analytical content providing valuable insights on crypto topics. Excludes promotional, fake, and non-influential accounts.",
  },
  // {
  //   title: 'Score changes',
  //   hiddenFor: [],
  //   isSticky: false,
  //   info: 'buy score changes compared to the last 24 hours',
  // },
  {
    title: "Price Percentage Change\n(Last 24h)",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: null,
  },
  {
    title: "Tweet Count",
    hiddenFor: [],
    isSticky: false,
    info: null,
  },
  {
    title: "Tweet Count Change\n(7 days)",
    hiddenFor: [],
    isSticky: false,
    info: "The percentage of changes in the number of posts compared to the last seven days",
  },
  {
    title: "Market Cap",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: null,
  },
  {
    title: "24h Volume",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: null,
  },
  {
    title: "Last 7 days price",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: null,
  },
];

const AssetsTable = ({
  data,
  type,
  maxHeight,
  minHeight,
  selectedCoins,
  setSelectedCoins,
  loading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef();
  const scrollDirection = useRef(null);

  const { events } = useScrollOnDrag(containerRef);
  const cryptosState = useSelector((state) => state.cryptos);
  const accountState = useSelector((state) => state.account);

  const [pinLoading, setPinLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemInfo, setItemInfo] = useState({
    infoTitle: "",
    info: "",
  });
  const [sortColumn, setSortColumn] = useState("signal");
  const [sortDirection, setSortDirection] = useState("desc");
  const [openLoginAlertModal, setLoginAlertModal] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const { isDragging, scrollTop } = useScrollOnDrag(containerRef);

  const [visibleItemsCount, setVisibleItemsCount] = useState(12);
  const [isLoading, setIsLoading] = useState(false);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const getLastSignalPercentage = (signal, lastSignal) => {
    const current = calculateGuageValue(
      signal[0] ?? 0,
      signal[1] ?? 0,
      signal?.null ?? 0
    );
    const last = calculateGuageValue(
      lastSignal[0] ?? 0,
      lastSignal[1] ?? 0,
      lastSignal?.null ?? 0
    );

    return Number(last - current);
  };

  function selectCoinHandler(event, coinId) {
    event.stopPropagation();
    const isChecked = event.target.checked;

    if (!isChecked) {
      setSelectedCoins(
        selectedCoins.filter((selectedItem) => selectedItem !== coinId)
      );
    } else {
      if (selectedCoins.length >= 3) {
        toast.remove();
        toast.error("You can only choose three items at a time");
      } else {
        setSelectedCoins([...selectedCoins, coinId]);
      }
    }
  }

  const watchListHandler = (event, coin_id) => {
    event.stopPropagation();

    if (accountState.isAuthenticated) {
      setPinLoading(true);
      const isCoinPinned = cryptosState.pinnedCoins?.findIndex(
        (coin) => coin.id === coin_id
      );

      if (isCoinPinned > -1) {
        unpinCryptoHandler(coin_id).then((res) => {
          if (res.status == 204) {
            toast.success("Crypto has been successfully unpinned");
            //@ts-ignore
            dispatch(fetchPinnedCoins());
            setPinLoading(false);
          }
        });
      } else {
        pinCryptoHandler(coin_id).then((res) => {
          if (res.status == 201) {
            toast.success("Crypto pinned successfully");
            //@ts-ignore
            dispatch(fetchPinnedCoins());
            setPinLoading(false);
          }
        });
      }
    } else {
      setLoginAlertModal(true);
    }
  };

  const isCryptoPinned = (crypto_id) => {
    const isCoinPinned = cryptosState.pinnedCoins?.findIndex(
      (coin) => coin.id === crypto_id
    );

    return isCoinPinned;
  };

  const calcPostChangeRate = (coin) => {
    const diffrence = Number(coin.tweet_count) - Number(coin.last_tweet_counts);
    const changeRatePercent =
      (diffrence / Number(coin.last_tweet_counts)) * 100;

    return changeRatePercent;
  };

  const calcSPSChangeRate = (coin) => {
    const currentValue = calculateGuageValue(
      coin.signal[0] ?? 0,
      coin.signal[1] ?? 0,
      coin.signal.null ?? 0
    );

    const lastValue = calculateGuageValue(
      coin.last_signal[0] ?? 0,
      coin.last_signal[1] ?? 0,
      coin.last_signal.null ?? 0
    );

    return currentValue - lastValue;
  };

  let sortedData =
    data &&
    [...data].sort((a, b) => {
      const aValue =
        sortTableData(sortColumn) === "signal"
          ? Object.keys(a.signal).length > 0 && a.tweet_count > 10
            ? calculateGuageValue(
                a.signal[0] ?? 0,
                a.signal[1] ?? 0,
                a.signal.null ?? 0
              )
            : -1
          : sortTableData(sortColumn) === "influencers"
            ? a.top_influencers?.length
            : sortTableData(sortColumn) === "last_tweet_counts"
              ? a.tweet_count
                ? calcPostChangeRate(a)
                : -1
              : sortTableData(sortColumn) === "tweet_count"
                ? Number(a.tweet_count ? a.tweet_count : 0)
                : sortTableData(sortColumn) === "latest_price"
                  ? Number(a.latest_price)
                  : sortTableData(sortColumn) === "last_signal"
                    ? isNaN(getLastSignalPercentage(a.signal, a.last_signal))
                      ? -1
                      : getLastSignalPercentage(a.signal, a.last_signal)
                    : sortTableData(sortColumn) === "price"
                      ? Math.abs(calculateDayPercentChange(a.prices))
                      : sortTableData(sortColumn) === "last 7 days price"
                        ? calculateWeekPercentChange(a.prices, a.latest_price)
                        : a[sortTableData(sortColumn)];

      const bValue =
        sortTableData(sortColumn) === "signal"
          ? Object.keys(b.signal).length > 0 && b.tweet_count > 10
            ? calculateGuageValue(
                b.signal[0] ?? 0,
                b.signal[1] ?? 0,
                b.signal.null ?? 0
              )
            : -1
          : sortTableData(sortColumn) === "influencers"
            ? b.top_influencers?.length
            : sortTableData(sortColumn) === "last_tweet_counts"
              ? b.tweet_count
                ? calcPostChangeRate(b)
                : -1
              : sortTableData(sortColumn) === "tweet_count"
                ? Number(b.tweet_count ? b.tweet_count : 0)
                : sortTableData(sortColumn) === "latest_price"
                  ? Number(b.latest_price)
                  : sortTableData(sortColumn) === "last_signal"
                    ? isNaN(getLastSignalPercentage(b.signal, b.last_signal))
                      ? -1
                      : getLastSignalPercentage(b.signal, b.last_signal)
                    : sortTableData(sortColumn) === "price"
                      ? Math.abs(calculateDayPercentChange(b.prices))
                      : sortTableData(sortColumn) === "last 7 days price"
                        ? calculateWeekPercentChange(b.prices, b.latest_price)
                        : b[sortTableData(sortColumn)];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      } else {
        return sortDirection === "asc"
          ? (aValue ?? "").localeCompare(bValue ?? "")
          : (bValue ?? "").localeCompare(aValue ?? "");
      }
    });

  if (cryptosState.pinnedCoins && cryptosState.pinnedCoins.length > 0) {
    const pinnedItems = sortedData.filter((item) => {
      return cryptosState.pinnedCoins.some(
        (pinnedItem) => pinnedItem.id === item.coin_id
      );
    });
    const remainingItems = sortedData.filter((item) => {
      return !cryptosState.pinnedCoins.some(
        (pinnedItem) => pinnedItem.id === item.coin_id
      );
    });

    const updatedSortedData = [...pinnedItems, ...remainingItems];
    sortedData = updatedSortedData;
  }

  useEffect(() => {
    const handleScroll = (e) => {
      const assetsInfo = document.getElementsByClassName("coinInfo");
      const loginButton = document.getElementsByClassName("loginBtn");
      const scrollTop = e.target.scrollTop;
      const scrollLeft = e.target.scrollLeft;
      let newTop = 485;
      let newWidth = 100;

      if (scrollLeft <= 100) {
        newWidth = 100 - scrollLeft;
      } else {
        newWidth = 0;
      }

      if (scrollTop <= 340) {
        newTop = 485 - scrollTop;
      } else {
        newTop = 150;
      }

      loginButton[0].style.top = `${newTop}px`;

      Array.from(assetsInfo).forEach((item) => {
        item.style.width = `${newWidth}px`;
      });
    };

    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const onSearch = (e) => {
    setSearchInputValue(e.target.value);
    setSearchedData(
      [...sortedData].filter(
        (item) =>
          item.title?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
          item.label?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      )
    );
  };

  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollHeight -
        containerRef.current.scrollTop -
        containerRef.current.clientHeight <
        300 &&
      !isLoading
    ) {
      setIsLoading(true);
      setVisibleItemsCount((prevCount) => prevCount + 8);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef.current]);

  useLayoutEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [visibleItemsCount]);

  useEffect(() => {
    if (isDragging || scrollTop > 0) {
      setVisibleItemsCount((prevCount) => prevCount + 8);
    }
  }, [isDragging, scrollTop]);

  const visibleData = sortedData?.slice(
    0,
    type === "Home" ? 100 : visibleItemsCount
  );

  const preventDiagonalScrolling = (e) => {
    const { scrollTop, scrollLeft } = e.target;
    const { prevScrollTop, prevScrollLeft } = containerRef.current;

    const coinLogoElement = document.getElementsByClassName("coin_logo");
    Array.from(coinLogoElement).forEach((logo) => {
      logo.style.transform = `scale(${getScale(
        containerRef.current.scrollLeft
      )})`;
    });

    if (window.innerWidth > 768) {
      const diffY = Math.abs(scrollTop - prevScrollTop);
      const diffX = Math.abs(scrollLeft - prevScrollLeft);

      if (diffY > 1) {
        e.target.scrollLeft = containerRef.current.prevScrollLeft;
      } else if (diffX > 1) {
        e.target.scrollTop = containerRef.current.prevScrollTop;
      }

      containerRef.current.prevScrollTop = containerRef.current.scrollTop;
      containerRef.current.prevScrollLeft = containerRef.current.scrollLeft;
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];

    containerRef.current.startY = touch.pageY;
    containerRef.current.startX = touch.pageX;

    scrollDirection.current = null;
  };

  const preventDiagonalMoving = (e) => {
    const touch = e.touches[0];
    const { startX, startY } = containerRef.current;

    const diffY = touch.pageY - startY;
    const diffX = touch.pageX - startX;

    if (!scrollDirection.current) {
      // Determine scroll direction
      if (Math.abs(diffY) > Math.abs(diffX)) {
        scrollDirection.current = "horizontal";
      } else {
        scrollDirection.current = "vertical";
      }
    }

    if (scrollDirection.current === "vertical") {
      if (Math.abs(diffX) > 0) {
        e.preventDefault();
        containerRef.current.scrollLeft -= diffX;
      }
    } else if (scrollDirection.current === "horizontal") {
      if (Math.abs(diffY) > 0) {
        e.preventDefault();
        containerRef.current.scrollTop -= diffY;
      }
    }

    containerRef.current.startY = touch.pageY;
    containerRef.current.startX = touch.pageX;
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", preventDiagonalScrolling);
      container.addEventListener("touchstart", handleTouchStart, {
        passive: true,
      });
      container.addEventListener("touchmove", preventDiagonalMoving, {
        passive: false,
      });

      return () => {
        container.removeEventListener("scroll", preventDiagonalScrolling);
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchmove", preventDiagonalMoving);
      };
    }
  }, [containerRef]);

  const getScale = (scrollX) => {
    const minScroll = 0;
    const maxScroll = 100;
    const minScale = 0.5;
    const maxScale = 1.0;

    if (scrollX < minScroll) return maxScale;
    if (scrollX > maxScroll) return minScale;

    // Calculate scale proportionally within the scroll range
    const scale =
      maxScale -
      ((scrollX - minScroll) / (maxScroll - minScroll)) * (maxScale - minScale);

    return scale;
  };

  return (
    <Box
      className={styles.assetsWrapper}
      sx={{ height: "100%", position: "relative" }}
    >
      <Box
        style={{
          height: "45px",
          width: "100%",
          position: "relative",
        }}
      >
        <TextField
          id="asset_search"
          label=""
          placeholder="Search for asset"
          variant="standard"
          onChange={(e) => onSearch(e)}
          onClick={() => {
            !accountState.isAuthenticated && setLoginAlertModal(true);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FiSearch
                  size={16}
                  style={{ marginTop: "-2px" }}
                  color={theme.palette.text.primary}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            background: (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.common.black
                : theme.palette.common.tableSolidColor,
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            margin: 0,
            padding: 0,

            ".MuiInputBase-root": {
              height: "45px",
              padding: "0 1rem",
              fontWeight: 100,
              fontSize: ".8rem",
            },

            "input::placeholder": {
              color: theme.palette.text.primary,
              opacity: theme.palette.mode == "light" ? 1 : 0.7,
            },
          }}
        />
      </Box>
      {searchInputValue !== "" && searchedData.length === 0 ? (
        <Box sx={{ py: 10 }}>
          <Typography
            variant="h6"
            align="center"
            sx={{ fontSize: ".9rem !important" }}
            color={theme.palette.text.primary}
          >
            No assets found. <br />
            Please try another search
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            className="loginBtn"
            sx={{
              display: accountState.isAuthenticated ? "none" : "block",
              position: "absolute",
              top: "485px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9,
            }}
          >
            <Button variant="contained" onClick={() => navigate("/login")}>
              Login For Full Access
            </Button>
          </Box>
          <TableContainer
            className={`customScrollbar ${
              theme.palette.mode === "light"
                ? "customScrollbar-light"
                : "customScrollbar-dark"
            }`}
            sx={{
              background: theme.palette.primary.light,
              maxHeight: maxHeight,
              height: minHeight,
              overflowY: "auto",
              paddingBottom: "1rem",
              userSelect: "none",
              touchAction: "none",
            }}
            {...events}
            ref={containerRef}
          >
            <Table
              stickyHeader
              aria-label="Coins table"
              sx={{
                maxHeight: maxHeight,
                background: theme.palette.primary.light,
              }}
            >
              <TableHead
                sx={{
                  background: theme.palette.primary.light,
                  zIndex: 100,
                  position: "sticky",
                }}
              >
                <TableRow>
                  {tabelHeaderData
                    .filter((item) => !item.hiddenFor.includes(type))
                    .map((tableItem) => (
                      <TableCell
                        onClick={() =>
                          handleSort(tableItem.title.toLowerCase())
                        }
                        key={tableItem.title}
                        align="left"
                        sx={{
                          position: "sticky",
                          left: tableItem.isSticky ? -1 : "auto",
                          top: -1,
                          cursor: "pointer",
                          zIndex: tableItem.isSticky ? 100 : 10,
                          background: theme.palette.common.tableSolidColor,
                          boxShadow:
                            tableItem.title === "Assets"
                              ? `inset -1px 0px 3px 0px ${
                                  theme.palette.mode == "light"
                                    ? "rgba(0, 0, 0, .1)"
                                    : "rgba(0, 0, 0, .7)"
                                }`
                              : "none",
                        }}
                      >
                        <Box
                          sx={{
                            whiteSpace: "nowrap",
                            textAlign: tableItem.isSticky ? "left" : "center",
                            fontWeight: 500,
                            fontSize: ".9rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent:
                              tableItem.title === "Assets"
                                ? "flex-start"
                                : "center",
                          }}
                          color={theme.palette.text.primary}
                        >
                          <TableTitle
                            hasInfo={tableItem.info ? true : false}
                            info={tableItem.info ? tableItem.info : ""}
                            infoTitle={tableItem.title}
                            open={open}
                            setOpen={setOpen}
                            setItemInfo={setItemInfo}
                          >
                            {tableItem.title}
                          </TableTitle>

                          {sortColumn === tableItem.title.toLowerCase() &&
                            (sortDirection === "asc" ? (
                              <BiChevronUp
                                size={17}
                                style={{ marginLeft: ".2rem" }}
                              />
                            ) : (
                              <BiChevronDown
                                size={17}
                                style={{ marginLeft: ".2rem" }}
                              />
                            ))}
                        </Box>
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {data &&
                  visibleData
                    .filter((item) =>
                      searchInputValue !== ""
                        ? item.title
                            ?.toLowerCase()
                            ?.includes(searchInputValue) ||
                          item.label?.toLowerCase()?.includes(searchInputValue)
                        : item.title
                    )
                    .map((coin, idx) => (
                      <TableRow
                        key={coin.id}
                        sx={{
                          cursor: "grab",
                          height: type === "shareOfVoice" ? "70px" : "auto",
                          filter:
                            idx >= 5 && !accountState.isAuthenticated
                              ? "blur(6px)"
                              : "unset",
                        }}
                      >
                        <TableCell
                          onClick={() => {
                            navigate(`/prices/${coin.full_id}/`, {
                              state: {
                                coin_id: coin.coin_id,
                              },
                            });
                          }}
                          className={styles.coinInfoWrapper}
                          sx={{
                            zIndex: 10,
                            padding: 0,
                            paddingRight: "10px",
                            height: "100%",
                            minHeight: "100px",
                            position: "sticky",
                            left: -1,
                            top: 0,
                            background: theme.palette.common.tableSolidColor,
                            boxShadow: `inset -1px 0px 3px 0px ${
                              theme.palette.mode == "light"
                                ? "rgba(0, 0, 0, .1)"
                                : "rgba(0, 0, 0, .7)"
                            }`,
                            // minWidth: "110px",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                          component="td"
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minHeight: "50px",
                            }}
                          >
                            {type === "shareOfVoice" ? (
                              <Checkbox
                                disabled={
                                  type === "shareOfVoice" &&
                                  !coin.tweet_count &&
                                  !coin.last_tweet_counts
                                    ? true
                                    : loading
                                      ? true
                                      : false
                                }
                                checked={
                                  selectedCoins.includes(coin.coin_id)
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  selectCoinHandler(e, coin.coin_id)
                                }
                              />
                            ) : (
                              <IconButton
                                size="small"
                                sx={{ marginLeft: ".2rem" }}
                                onClick={(e) =>
                                  watchListHandler(e, coin.coin_id)
                                }
                                disabled={pinLoading}
                              >
                                {isCryptoPinned(coin.coin_id) > -1 ? (
                                  <RiPushpin2Fill
                                    size={15}
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <RiPushpin2Line size={15} />
                                )}
                              </IconButton>
                            )}
                            <DefaultTooltip title="Based on SPS Change"></DefaultTooltip>
                            <Box className="coin_logo">
                              <Badge
                                badgeContent={
                                  <DefaultTooltip title="Based on SPS Change">
                                    <div
                                      className={
                                        calcSPSChangeRate(coin) > 0
                                          ? styles.greenPulse
                                          : calcSPSChangeRate(coin) < 0
                                            ? styles.redPulse
                                            : null
                                      }
                                    />
                                  </DefaultTooltip>
                                }
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                overlap="circular"
                              >
                                <img
                                  src={getCoinImage(coin.coin_id)}
                                  alt={coin.logo}
                                  className={`${styles.coinLogo}`}
                                  onClick={() => {
                                    navigate(`/prices/${coin.full_id}/`, {
                                      state: {
                                        coin_id: coin.coin_id,
                                      },
                                    });
                                  }}
                                />
                              </Badge>
                            </Box>
                            <Box
                              sx={{
                                transition: "maxWidth 0.1s",
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                              className="coinInfo"
                            >
                              <Typography
                                variant="caption"
                                component="h2"
                                sx={{ whiteSpace: "nowrap" }}
                                color={theme.palette.text.primary}
                              >
                                {coin.label}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="h3"
                                sx={{
                                  whiteSpace: "nowrap",
                                  paddingRight: "6px",
                                }}
                                color={theme.palette.text.primary}
                              >
                                {coin.title &&
                                  coin.title[0]?.toUpperCase() +
                                    coin.title.slice(1, 10)}
                                {coin.title?.length > 10 ? "..." : ""}
                              </Typography>
                              <Typography
                                variant="body2"
                                component="h3"
                                sx={{ whiteSpace: "nowrap" }}
                                color={theme.palette.text.primary}
                              >
                                $
                                {formatPrice(coin.latest_price) > 1
                                  ? Number(
                                      formatPrice(coin.latest_price)
                                    ).toLocaleString()
                                  : formatPrice(coin.latest_price)}
                              </Typography>
                            </Box>
                            {/* </Box> */}
                          </Box>
                        </TableCell>
                        {/* {type !== "shareOfVoice" && (
                    <TableCell component="td" align="center">
                      <Typography
                        variant="caption"
                        component="h6"
                        sx={{ whiteSpace: "nowrap", paddingLeft: ".2rem" }}
                        color={theme.palette.text.primary}
                      >
                        $
                        {formatPrice(coin.latest_price) > 1
                          ? Number(
                              formatPrice(coin.latest_price)
                            ).toLocaleString()
                          : formatPrice(coin.latest_price)}
                      </Typography>
                    </TableCell>
                  )} */}

                        <TableCell component="td" align="center">
                          {coin.tweet_count >= 10 &&
                          Object.values(coin.signal).reduce(
                            (acc, curr) => acc + curr,
                            0
                          ) >= 10 ? (
                            <StatisticsGuage
                              value={calculateGuageValue(
                                coin.signal[0] ?? 0,
                                coin.signal[1] ?? 0,
                                coin.signal.null ?? 0
                              )}
                              lastValue={calculateGuageValue(
                                coin.last_signal[0] ?? 0,
                                coin.last_signal[1] ?? 0,
                                coin.last_signal.null ?? 0
                              )}
                            />
                          ) : (
                            <TableTitle
                              hasInfo
                              info="Given the number of tweets influencers have had in the last 24 hours, providing a signal is not available"
                              infoTitle="NA"
                              isCenter
                            >
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap",
                                  color: "#FA637A",
                                  fontSize: "10px",
                                }}
                              >
                                N/A
                              </Typography>
                            </TableTitle>
                          )}
                        </TableCell>

                        {type !== "shareOfVoice" && (
                          <TableCell component="td" align="center">
                            {coin.top_influencers?.length > 0 ? (
                              <Box className={styles.coinsTopInfluencers}>
                                {coin.top_influencers.slice(0, 3).map((inf) => (
                                  <Link
                                    to={`/influencers/${inf.screen_name}`}
                                    state={{ id: inf.user_id_str }}
                                    key={`${coin.id}-${inf.user_id_str}`}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Avatar
                                      screenName={inf.screen_name}
                                      width={28}
                                      height={28}
                                    />
                                  </Link>
                                ))}
                              </Box>
                            ) : (
                              <Typography
                                variant="caption"
                                component="h6"
                                sx={{
                                  whiteSpace: "nowrap",
                                  color: theme.palette.text.primary,
                                }}
                              >
                                -
                              </Typography>
                            )}
                          </TableCell>
                        )}

                        {/* <TableCell component='td' align='center'>
                    {coin.signal && coin.last_signal ? (
                      <Typography
                        component='h6'
                        sx={{
                          whiteSpace: 'nowrap',
                          color:
                            getLastSignalPercentage(coin.signal, coin.last_signal) >= 0
                              ? '#259659'
                              : '#FA637A',
                        }}
                      >
                        {isNaN(getLastSignalPercentage(coin.signal, coin.last_signal))
                          ? 'N/A'
                          : `${getLastSignalPercentage(coin.signal, coin.last_signal)}%`}
                      </Typography>
                    ) : (
                      '...'
                    )}
                  </TableCell> */}
                        {type !== "shareOfVoice" && (
                          <TableCell component="td" align="center">
                            {coin.prices && coin.prices.length > 0 ? (
                              <Typography
                                variant="caption"
                                component="h6"
                                sx={{
                                  whiteSpace: "nowrap",
                                  color:
                                    calculateDayPercentChange(coin.prices) < 0
                                      ? "#FA637A"
                                      : theme.palette.common.greenText,
                                }}
                              >
                                {calculateDayPercentChange(coin.prices) < 0
                                  ? "-"
                                  : "+"}
                                {formatPrice(
                                  Math.abs(
                                    calculateDayPercentChange(coin.prices)
                                  )
                                )}
                                %
                              </Typography>
                            ) : (
                              <Typography> - </Typography>
                            )}
                          </TableCell>
                        )}
                        <TableCell component="td" align="center">
                          {coin.tweet_count ? (
                            <Typography
                              variant="caption"
                              component="h6"
                              sx={{ whiteSpace: "nowrap" }}
                              color={theme.palette.text.primary}
                            >
                              {Number(coin.tweet_count).toLocaleString() < 10
                                ? "<10"
                                : Number(
                                    coin.tweet_count
                                  ).toLocaleString()}{" "}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              component="h6"
                              sx={{ whiteSpace: "nowrap" }}
                              color={theme.palette.text.primary}
                            >
                              {"<10"}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell component="td" align="center">
                          {coin.tweet_count ? (
                            <Typography
                              variant="caption"
                              component="h6"
                              sx={{ whiteSpace: "nowrap" }}
                              color={
                                calcPostChangeRate(coin) <= -3
                                  ? " #FA637A"
                                  : calcPostChangeRate(coin) > 3
                                    ? "#2FDB7D"
                                    : theme.palette.text.primary
                              }
                            >
                              {calcPostChangeRate(coin) < 0
                                ? "-"
                                : calcPostChangeRate(coin) > 0
                                  ? "+"
                                  : ""}
                              {formatPrice(Math.abs(calcPostChangeRate(coin)))}%
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              component="h6"
                              sx={{
                                whiteSpace: "nowrap",
                                color: theme.palette.text.primary,
                              }}
                            >
                              Coming soon
                            </Typography>
                          )}
                        </TableCell>
                        {type !== "shareOfVoice" && (
                          <TableCell component="td" align="center">
                            {coin.market_cap ? (
                              <Typography
                                variant="caption"
                                component="h6"
                                sx={{ whiteSpace: "nowrap" }}
                                color={theme.palette.text.primary}
                              >
                                {formatNumber(coin.market_cap).toLocaleString()}{" "}
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                component="h6"
                                sx={{ whiteSpace: "nowrap" }}
                                color={theme.palette.text.primary}
                              >
                                Coming soon
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        {type !== "shareOfVoice" && (
                          <TableCell component="td" align="center">
                            {coin.volume ? (
                              <Typography
                                variant="caption"
                                component="h6"
                                sx={{ whiteSpace: "nowrap" }}
                                color={theme.palette.text.primary}
                              >
                                {formatNumber(coin.volume).toLocaleString()}{" "}
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                component="h6"
                                sx={{ whiteSpace: "nowrap" }}
                                color={theme.palette.text.primary}
                              >
                                Coming soon
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        {type !== "shareOfVoice" && (
                          <TableCell
                            component="td"
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              padding: "0",
                              height: "70px !important",
                              overflow: "hidden",
                            }}
                          >
                            <Box
                              className={`${styles.cryptoCardChart} smallAreaChartContainer`}
                            >
                              {coin.prices && (
                                <SmallAreaChart
                                  name="Coin"
                                  data={[...coin.prices]
                                    .slice(0, 5)
                                    .reverse()
                                    .map((price) => ({
                                      x: price.date,
                                      y: price.avg_price.toFixed(10),
                                    }))}
                                  color={
                                    calculateWeekPercentChange(
                                      coin.prices,
                                      coin.latest_price
                                    )
                                      .toString()
                                      .includes("-")
                                      ? "#FA637A"
                                      : "#75D6A1"
                                  }
                                />
                              )}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </Box>
  );
};

export default AssetsTable;
