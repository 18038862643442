import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LuInfo, LuNewspaper } from "react-icons/lu";
import { GiTwoCoins } from "react-icons/gi";
import { NewsItem } from "./interfaces";
import DashboardLayout from "../../layout/dashboardLayout";
import AnalyticsTitle from "../../components/common/title/AnalyticsTitle";
import AssetsTable from "../../components/common/assetsTable";
import Loading from "../../components/common/loading";
import Overall from "../../components/analytics/overall";
import NewsCard from "../../components/common/newsCard";
import FinancialChart from "../../components/crypto/financialChart";
import SocialMediaActivityChart from "../../components/charts/SocialMediaActivityChart";
import ViewMoreButton from "../../components/common/buttons/ViewMoreButton";
import BuyAndNotBuySignals from "../../components/analytics/signals";
import Performers from "../../components/analytics/performers";
import RankedInfluencers from "../../components/analytics/rankedInfluencers";
import NewsRssCard from "../../components/news/newsRssCard";
import ChatBanner from "../../components/analytics/chatBanner";
import CoinInfo from "../../components/analytics/singleCoin/coinInfo";
import CoinSignals from "../../components/analytics/singleCoin/coinSignals";
import CoinPerformance from "../../components/analytics/singleCoin/coinPerformance";
import CoinNews from "../../components/analytics/singleCoin/coinNews";
import CoinSps from "../../components/analytics/singleCoin/coinSps";
import CryptoList from "../../components/crypto/cryptoList";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { fetchAllMovers } from "../../features/crypto/moversSlice";
import {
  fetchCoinsStatistics,
  fetchPinnedCoins,
  fetchSPSChanges,
  fetchTreemapData,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import { fetchTopRssNews, fetchTopTweets } from "../../features/news/newsSlice";
import {
  fetchInfluencersSpectrum,
  fetchSortInfluencers,
  fetchTopInfluencers,
  fetchTopInfluencersCoin,
} from "../../features/influencers/influencersSlice";
import { fetchCryptoTweetCount } from "../../features/crypto/cryptoNews";
import {
  fetchCoinSignalCount,
  fetchInfluencerSignal,
} from "../../features/signal/signalSlice";
import { fetchSingleAssetPerformance } from "../../features/crypto/cryptoSlice";
import useAuth from "../../utils/useAuth";
import {
  calculateDayPercentChange,
  calculateGuageValue,
  convertSPSChangesPeriodName,
  getDatePeriod,
} from "../../utils/functions";
import DefaultTooltip from "../../components/common/tooltip";
import styles from "./analytics.module.scss";
import TreeMap from "../../components/charts/TreeMap";
import HeatMap from "../../components/charts/HeatMap";
import TimeFrame from "../../components/analytics/timeFrame";
import moment from "moment";

const Analytics = () => {
  const theme = useTheme();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  // redux states
  const account: any = useSelector((state: RootState) => state.account);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const moversState: any = useSelector((state: RootState) => state.movers);
  const newsState: any = useSelector((state: RootState) => state.news);
  const signalState: any = useSelector((state: RootState) => state.signal);
  const cryptoNewsState: any = useSelector(
    (state: RootState) => state.cryptoNews
  );
  const influencersState: any = useSelector(
    (state: RootState) => state.influencers
  );

  // timeframe states
  const [socialActivityTimeFrame, setSocialActivityTimeFrame] = useState<
    string | null
  >("1W");
  const [treemapTimeFrame, setTreemapTimeFrame] = useState<string | null>(
    "24H"
  );

  useEffect(() => {
    if (isAuthValid) {
      if (isEmpty(influencersState.sortedInfluencers)) {
        dispatch(
          fetchSortInfluencers({
            id: 0,
            type: "relevant_tweets",
            timePeriod: 7,
          })
        );
        dispatch(
          fetchSortInfluencers({ id: 1, type: "buy_signal", timePeriod: 7 })
        );
        dispatch(
          fetchSortInfluencers({ id: 2, type: "sell_signal", timePeriod: 7 })
        );
        dispatch(
          fetchSortInfluencers({
            id: 3,
            type: "positive_sentiment",
            timePeriod: 7,
          })
        );
        dispatch(
          fetchSortInfluencers({
            id: 4,
            type: "negative_sentiment",
            timePeriod: 7,
          })
        );
      }
      if (isEmpty(influencersState.topInfluencersCoin)) {
        dispatch(fetchTopInfluencersCoin(3));
      }
      if (isEmpty(cryptosState.spsChanges)) {
        dispatch(fetchSPSChanges());
      }
    }
  }, [isAuthValid]);

  useEffect(() => {
    if (isAuthValid) {
      if (!cryptosState.coinsStatistics) {
        dispatch(fetchCoinsStatistics(1)).then((res) =>
          dispatch(
            setCoinStatistics({
              page: 1,
              result: cryptosState.coinStatisticsState.result.concat(
                res.payload
              ),
            })
          )
        );
      }
      if (!moversState.data) {
        dispatch(fetchAllMovers());
      }
      if (!newsState.topTweets) {
        dispatch(fetchTopTweets());
      }

      if (!newsState.topRssNews) dispatch(fetchTopRssNews());

      if (!influencersState.topInfluencers) {
        dispatch(fetchTopInfluencers());
      }
      if (influencersState.influencersSpectrum.length == 0) {
        dispatch(fetchInfluencersSpectrum());
      }
    }
  }, [cryptosState.availableCoins, isAuthValid]);

  useEffect(() => {
    if (account.isAuthenticated) {
      if (!cryptosState.pinnedCoins) {
        dispatch(fetchPinnedCoins());
      }
    }
  }, [account.isAuthenticated, cryptosState.pinnedCoins]);

  const coinsSelectorHandler = (selectedCoin: number) => {
    const coinSignalRequest = {
      coin: selectedCoin,
      page: 1,
      category: cryptoState.analyticsSelectedNewsTab,
    };

    dispatch(fetchSingleAssetPerformance(selectedCoin));
    dispatch(fetchInfluencerSignal(coinSignalRequest));
    // @ts-ignore
    dispatch(fetchCoinSignalCount(selectedCoin));
  };

  useEffect(() => {
    if (
      isAuthValid &&
      !signalState.influencerSignal &&
      !cryptoState.coinPerformance
    ) {
      coinsSelectorHandler(cryptoState.analyticsSelectedCoin);
    }
  }, [isAuthValid, cryptoState.analyticsSelectedCoin]);

  useEffect(() => {
    const { startDate, endDate } = getDatePeriod(treemapTimeFrame);

    const requestData = {
      startDate,
      endDate,
    };

    if (isAuthValid) {
      dispatch(fetchTreemapData(requestData));
    }
  }, [isAuthValid, treemapTimeFrame]);

  useEffect(() => {
    const { startDate, endDate } = getDatePeriod(socialActivityTimeFrame);

    const tweetCountRequest = {
      coinId: cryptoState.analyticsSelectedCoin,
      startDate,
      endDate,
    };
    if (isAuthValid) {
      dispatch(fetchCryptoTweetCount(tweetCountRequest));
    }
  }, [isAuthValid, cryptoState.analyticsSelectedCoin, socialActivityTimeFrame]);

  const postsCountData = useMemo(() => {
    console.log(cryptoState.analyticsSelectedCoin);
    if (
      !isEmpty(signalState.cryptoSignalCount) &&
      cryptoState.analyticsSelectedCoin
    ) {
      const allSignals = signalState.cryptoSignalCount[
        cryptoState.analyticsSelectedCoin
      ]?.map((signal: any) => {
        const yValue = calculateGuageValue(
          signal.zero_signals ?? 0,
          signal.one_signals ?? 0,
          0
        );
        return {
          x: signal.datetime,
          y: isNaN(yValue) ? 0 : yValue,
        };
      });

      const sortData = allSignals?.sort(
        (a: any, b: any) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );

      return sortData;
    }
  }, [signalState.cryptoSignalCount, cryptoState.analyticsSelectedCoin]);

  const pricesData = useMemo(() => {
    if (signalState.cryptoSignalCount && cryptoState.analyticsSelectedCoin) {
      const allPrices = signalState?.cryptoSignalCount[
        cryptoState.analyticsSelectedCoin
      ]?.map((price: any) => ({
        x: price.datetime,
        y: +price.avg_price.toFixed(1),
        normalize: +price.avg_price.toFixed(1),
      }));
      const sortData = allPrices?.sort(
        (a: any, b: any) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );
      return sortData;
    }
  }, [signalState.cryptoSignalCount, cryptoState.analyticsSelectedCoin]);

  const treemapDataSeries = useMemo(() => {
    if (isEmpty(cryptosState.treemapData)) return;
    const normalizeSize = (value: number, min: number, max: number) => {
      if (max === min) return 100;
      return ((value - min) / (max - min)) * 100;
    };

    const yValues = cryptosState.treemapData?.map(
      (coin: any) => coin.tweet_count
    );
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    const dataSchema = cryptosState.treemapData
      ?.map((coin: any) => ({
        x: coin.coin.code,
        y: coin.tweet_count,
        fillColor:
          Number(calculateDayPercentChange(coin.prices).toFixed(2)) < 0
            ? "#E7837E"
            : "#64BE7C",
        priceChange: Number(calculateDayPercentChange(coin.prices).toFixed(2)),
        size: normalizeSize(coin.tweet_count, minY, maxY),
      }))
      .slice(0, 10);

    return dataSchema;
  }, [cryptosState.treemapData]);

  const heatmapDataSeries = useMemo(() => {
    if (isEmpty(cryptosState.availableCoins) || !cryptosState.spsChanges)
      return;
    const coinList = cryptosState.availableCoins;
    const copySPSChanges = [...cryptosState.spsChanges];

    const sortedDataByRank = copySPSChanges?.sort((a: any, b: any) => {
      const firstItemRank = coinList?.find(
        (i: any) => i.id === a.coin.id
      )?.rank;
      const secondItemRank = coinList?.find(
        (i: any) => i.id === b.coin.id
      )?.rank;

      return firstItemRank - secondItemRank;
    });

    const dataSchema = sortedDataByRank?.map((coin: any) => {
      let data: { x: string; y: number }[] = [];
      for (const key in coin.sps_changes) {
        const spsChanges = {
          x: convertSPSChangesPeriodName(key) as string,
          y: coin.sps_changes[key] as number,
        };
        data.push(spsChanges);
      }

      return {
        name: coin.coin.name as string,
        data,
        id: coin.coin.id,
      };
    });

    return dataSchema;
  }, [cryptosState.spsChanges, cryptosState.availableCoins]);
  return (
    <DashboardLayout
      title="Coinfident Crypto Analytics Platform"
      description="Track technical, on-chain, and social signals all in one place with Coinfident's analytics platform for enhanced trading insights."
    >
      <Box sx={{ maxWidth: "2000px", mx: "auto" }}>
        <Box className={styles.analyticsPage}>
          <Grid container gap={1} justifyContent="space-between">
            {downLg && (
              <Grid
                item
                lg={2.9}
                xs={12}
                className="analytics-card"
                sx={{
                  background: theme.palette.primary.light,
                  maxHeight: "300px",
                }}
              >
                <AnalyticsTitle title="Market Social Prediction Signal" />
                <Overall overallSignal={cryptosState.overallSignal} />
              </Grid>
            )}

            <Grid
              item
              lg={3.85}
              xs={12}
              className="analytics-card"
              sx={{
                background: theme.palette.primary.light,
                minHeight: "495px",
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
              }}
            >
              <BuyAndNotBuySignals
                title="Top Coins with Buy SPS"
                type="buy_signal"
                loading={cryptosState.coinsStatisticsLoading}
                data={cryptosState.coinsStatistics}
              />
            </Grid>
            <Grid
              item
              lg={3.85}
              xs={12}
              className="analytics-card"
              sx={{
                background: theme.palette.primary.light,
                minHeight: "495px",
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
              }}
            >
              <BuyAndNotBuySignals
                title="Top Coins with Not-Buy SPS"
                type="sell_signal"
                loading={cryptosState.coinsStatisticsLoading}
                data={cryptosState.coinsStatistics}
              />
            </Grid>
            <Grid
              item
              lg={2}
              xs={12}
              className="analytics-card"
              sx={{
                minHeight: "495px",
                background: (theme: any) => theme.palette.primary.light,
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
              }}
            >
              <AnalyticsTitle
                isCoinMarketCap
                isLarge
                title="Top Gainers"
                info="Price Percentage Change"
              />
              <Performers moversData={moversState} type="top_gainers" />
              <ViewMoreButton link="/prices" />
            </Grid>
            <Grid
              item
              lg={2}
              xs={12}
              className="analytics-card"
              sx={{
                background: (theme: any) => theme.palette.primary.light,
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
              }}
            >
              <AnalyticsTitle
                isCoinMarketCap
                isLarge
                title=" Top Losers"
                info="Price Percentage Change"
              />
              <Performers moversData={moversState} type="top_losers" />
              <ViewMoreButton link="/prices" />
            </Grid>
          </Grid>

          <Grid container gap={1} mt={2} justifyContent="space-between">
            {!downLg && (
              <Grid
                item
                lg={2.9}
                xs={12}
                className="analytics-card"
                sx={{
                  maxHeight: "300px",
                  background: theme.palette.primary.light,
                  border: (theme: any) =>
                    `1px solid ${theme.palette.common.darkPrimary}`,
                }}
              >
                <AnalyticsTitle title="Market Social Prediction Signal" />
                <Overall overallSignal={cryptosState.overallSignal} />
              </Grid>
            )}
            <Grid
              item
              lg={9}
              xs={12}
              className="analytics-card"
              sx={{
                background: theme.palette.primary.light,
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
              }}
            >
              <AnalyticsTitle title=" Top Crypto Leading Voices on X" isLarge />
              <Grid
                container
                gap={1}
                sx={{
                  padding: ".7rem",
                  minHeight: "255px",
                  justifyContent: "space-between",
                }}
              >
                <RankedInfluencers />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              mt={2}
              className="analytics-card"
              sx={{
                minHeight: "500px",
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
                background: theme.palette.primary.light,
              }}
            >
              <AnalyticsTitle
                title="Screeners"
                info="Today's Cryptocurrency Prices and Signals"
                hasTradeBtn
                hasViewMoreBtn
                viewMoreLink="/prices"
                isLarge
              />
              {cryptosState.coinsStatisticsLoading ? (
                <Loading />
              ) : (
                <>
                  {
                    //@ts-ignore
                    <AssetsTable
                      data={cryptosState.coinsStatistics}
                      maxHeight={500}
                      type="Home"
                    />
                  }
                </>
              )}
              <ViewMoreButton link="/prices" />
            </Grid>
            <Grid
              className="analytics-card"
              sx={{
                minHeight: "350px",
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
                background: theme.palette.primary.light,
              }}
              item
              xs={12}
              lg={6}
            >
              <AnalyticsTitle
                title="Influencer Tweet Distribution by Coin"
                isLarge
                minHeight={55}
                utils={
                  <TimeFrame
                    timePeriod={treemapTimeFrame}
                    setTimePeriod={setTreemapTimeFrame}
                    timeFrames={["24H", "3D", "1W", "1M"]}
                  />
                }
              />
              {cryptosState.treemapDataLoading ? (
                <Box
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Loading />
                </Box>
              ) : (
                <TreeMap data={treemapDataSeries} />
              )}
            </Grid>

            <Grid
              className="analytics-card"
              sx={{
                minHeight: "350px",
                border: (theme: any) =>
                  `1px solid ${theme.palette.common.darkPrimary}`,
                background: theme.palette.primary.light,
              }}
              item
              xs={12}
              lg={5.9}
            >
              <AnalyticsTitle
                title="SPS Changes Over Time"
                isLarge
                minHeight={55}
              />
              {cryptosState.availableCoinsLoading ||
              cryptoState.spsChangesLoading ||
              heatmapDataSeries === undefined ? (
                <Box
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Loading />
                </Box>
              ) : (
                <HeatMap data={heatmapDataSeries?.slice(0, 8)} />
              )}
            </Grid>
          </Grid>

          <Box>
            <ChatBanner />
          </Box>

          {!downLg && (
            <>
              <Box
                sx={{
                  my: 3,
                  pb: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottom: `1px solid ${theme.palette.grey[600]}`,
                }}
              >
                <Typography
                  variant="h6"
                  color={theme.palette.text.primary}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <LuNewspaper size={23} style={{ marginRight: ".5rem" }} />{" "}
                  Latest News
                </Typography>
              </Box>
              <Grid container gap={1} justifyContent="space-between">
                <Grid
                  item
                  xs={12}
                  lg={5.94}
                  className="analytics-card"
                  sx={{
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <AnalyticsTitle
                    isLarge
                    title="Top Posts"
                    info="Top Community Posts"
                  />
                  {newsState.topTweetsLoading ? (
                    <Loading />
                  ) : (
                    <Box
                      sx={{ height: "500px", overflowY: "auto" }}
                      className={`customScrollbar ${
                        theme.palette.mode === "light"
                          ? "customScrollbar-light"
                          : "customScrollbar-dark"
                      }`}
                      p={2}
                    >
                      {newsState.topTweets?.length > 0 ? (
                        newsState.topTweets?.map(
                          (news: NewsItem, idx: number) => (
                            <NewsCard
                              key={idx}
                              screen_name={news.screen_name}
                              id={news.user_id_str}
                              name={news.screen_name}
                              media={news.media}
                              date={`${moment
                                .parseZone(news.created_at)
                                .format("DD MMMM YYYY")}`}
                              content={news.full_text}
                              reply_count={
                                news.reply_count ? news.reply_count : 0
                              }
                              favorite_count={
                                news.favorite_count ? news.favorite_count : 0
                              }
                              retweet_count={
                                news.retweet_count ? news.retweet_count : 0
                              }
                            />
                          )
                        )
                      ) : (
                        <Typography
                          variant="h6"
                          color={theme.palette.text.primary}
                          sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          No Data
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={5.94}
                  className="analytics-card"
                  sx={{
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <AnalyticsTitle
                    isLarge
                    title="Top RSS News"
                    info="Top Community Articles"
                  />
                  {newsState.topTweetsLoading ? (
                    <Loading />
                  ) : (
                    <Box
                      sx={{ height: "500px", overflowY: "auto" }}
                      className={`customScrollbar ${
                        theme.palette.mode === "light"
                          ? "customScrollbar-light"
                          : "customScrollbar-dark"
                      }`}
                      p={2}
                    >
                      {newsState.topRssNewsLoading ? (
                        <Loading />
                      ) : newsState.topRssNews?.results?.length > 0 ? (
                        newsState.topRssNews.results?.map(
                          (rssNews: any, idx: number) => (
                            <NewsRssCard
                              key={`${rssNews.title}-${rssNews.published}`}
                              url={rssNews.url ?? ""}
                              thumbnail_url={rssNews.thumbnail_url}
                              title={rssNews.title}
                              base_link={rssNews.base_link}
                              published={moment
                                .parseZone(rssNews.published)
                                .format("YYYY-MM-DD")}
                              tags={rssNews.tags}
                            />
                          )
                        )
                      ) : (
                        <Typography
                          variant="h6"
                          color={theme.palette.text.primary}
                          sx={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          No Data
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: 12,
                  mb: 3,
                  pb: 0.5,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottom: `1px solid ${theme.palette.grey[600]}`,
                }}
              >
                <Typography
                  variant="h6"
                  color={theme.palette.text.primary}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <GiTwoCoins size={23} style={{ marginRight: ".5rem" }} />{" "}
                  Crypto Section
                </Typography>

                <Box sx={{ position: "absolute", left: 0, top: "-12px" }}>
                  <CryptoList
                    isAnalytics
                    coinsSelectorHandler={coinsSelectorHandler}
                  />
                </Box>
              </Box>

              <Grid container gap={1} justifyContent="space-between">
                <Grid
                  item
                  xs={12}
                  lg={4.4}
                  p={3}
                  className="analytics-card"
                  sx={{
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <CoinInfo
                    cryptoId={cryptoState.analyticsSelectedCoin}
                    cryptoData={cryptosState.coinsStatistics?.find(
                      (coin: any) =>
                        coin.coin_id == cryptoState.analyticsSelectedCoin
                    )}
                  />

                  <CoinSignals
                    signalState={signalState}
                    cryptoData={cryptosState.coinsStatistics?.find(
                      (coin: any) =>
                        coin.coin_id == cryptoState.analyticsSelectedCoin
                    )}
                  />

                  <CoinPerformance data={cryptoState} size="small" />

                  <Typography
                    mt={8}
                    align="center"
                    color={theme.palette.text.primary}
                  >
                    Social Media Activity
                    <DefaultTooltip title="This indicator shows the fluctuation in the number of X posts from influencers and news outlets over time for each coin.">
                      <span style={{ marginLeft: 4 }}>
                        <LuInfo />
                      </span>
                    </DefaultTooltip>
                  </Typography>

                  {cryptoNewsState.cryptoNewsCountLoading ? (
                    <Loading />
                  ) : (
                    cryptoNewsState.cryptoNewsCount && (
                      <SocialMediaActivityChart
                        name="News count"
                        data={cryptoNewsState.cryptoNewsCount}
                        timeFrame={socialActivityTimeFrame}
                        setTimeFrame={setSocialActivityTimeFrame}
                      />
                    )
                  )}
                </Grid>
                <Grid container item xs={12} lg={7.5}>
                  <Grid
                    item
                    xs={12}
                    className="analytics-card"
                    sx={{
                      overflow: "hidden",
                      background: theme.palette.primary.light,
                      border: (theme: any) =>
                        `1px solid ${theme.palette.common.darkPrimary}`,
                    }}
                  >
                    <AnalyticsTitle isLarge title="Crypto Chart" />
                    {cryptosState.coinsStatisticsLoading ? (
                      <Loading />
                    ) : (
                      <FinancialChart
                        symbol={cryptoState.analyticsSelectedCoin}
                        coinsList={cryptosState.coinsStatistics}
                      />
                    )}
                  </Grid>

                  <Grid container mt={2} justifyContent="space-between">
                    <Grid
                      item
                      xs={12}
                      lg={5.94}
                      className="analytics-card"
                      sx={{
                        zIndex: 3,
                        background: theme.palette.primary.light,
                        border: (theme: any) =>
                          `1px solid ${theme.palette.common.darkPrimary}`,
                      }}
                    >
                      <CoinNews signalState={signalState} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={5.94}
                      className="analytics-card"
                      sx={{
                        overflow: "hidden",
                        background: theme.palette.primary.light,
                        border: (theme: any) =>
                          `1px solid ${theme.palette.common.darkPrimary}`,
                      }}
                    >
                      <CoinSps
                        postsCountData={postsCountData}
                        pricesData={pricesData}
                        selectedCoin={cryptoState.analyticsSelectedCoin}
                        loading={signalState.cryptoSignalCountLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Analytics;
