import { Box, Modal, Typography, useTheme } from "@mui/material";
import { DefaultModalProps } from "../interfaces";

const DefaultModal: React.FC<DefaultModalProps> = ({
  open,
  setOpen,
  title,
  content,
  maxWidth,
  isLock,
}) => {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: maxWidth ?? "400px",
    maxHeight: "80vh", // محدود کردن ارتفاع برای اسکرول
    borderRadius: "20px",
    bgcolor:
      theme.palette.mode === "light"
        ? theme.palette.grey["600"]
        : theme.palette.grey["800"],
    boxShadow: 24,
    p: 3,
    overflow: "auto", // فعال کردن اسکرول
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(isLock ? true : !open)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            color={theme.palette.primary.main}
            sx={{
              paddingBottom: "4px",
              borderBottom: `1px solid
            ${
              //@ts-ignore
              theme.palette.grey.light
            }`,
            }}
          >
            {title}
          </Typography>
          {content}
        </Box>
      </Modal>
    </div>
  );
};

export default DefaultModal;
