import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00AEEF", // change
      light: "rgba(19, 22, 21, .75)",
    },
    secondary: {
      main: "#B9E5FA", // change
    },
    text: {
      primary: "#fff",
      secondary: "#31574C",
      disabled: "linear-gradient(180deg, #636563 0%, #2e3030 100%)",
    },
    background: {
      default: "#0A0E0D",
      paper:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.09) 100%)",
    },
    error: {
      main: "#fa637a",
    },
    warning: {
      main: "#00AEEF",
    },
    common: {
      card: "linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(244, 244, 244, 0.29) 0.01%, rgba(0, 0, 0, 0.09) 100%)",
      solidCard: "linear-gradient(180deg, #5D5F5F 0%, #2F3231 100%)",
      green: "#026C62",
      backdrop: "rgba(0, 0, 0, .9)",
      popup: "linear-gradient(180deg, #494b4b 0%, #1A1D1D 100%)",
      mute: "#999",
      footer: "rgba(0, 0, 0, 0.7)",
      whiteToGreen: "#fff",
      greenText: "#75D6A1",
      whiteToDarkGreen: "#fff",
      tableSolidColor: "#111413",
      primaryToGreen: "#00AEEF", // change
      greenToWhite: "#B9E5FA", // change
      icon: "#707070",
      selectedFooterItem: "#333736",
      darkPrimary: "#353535",
      agentModalBg: "#252525",
      agentBg: "#353535",
      chatInput: "#232726",
    },
    grey: {
      main: "rgba(217, 215, 215, .2)",
      light: "rgba(217, 215, 215, 0.5)",
      dark: "rgba(217, 215, 215, 0.2)",
      700: "rgba(255, 255, 255, 0.50)",
      400: "#D9D7D7",
    },
  },
  typography: {
    allVariants: {
      color: "#fff",
    },
    fontFamily: "Poppins",
  },
});
