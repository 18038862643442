import { Avatar, Box, useTheme } from "@mui/material";
import { heatMapProps } from "./interfaces";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { getCoinImage } from "../../utils/functions";
import styles from "./charts.module.scss";
import Empty from "../common/empty/Empty";

const HeatMap: React.FC<heatMapProps> = ({ data }) => {
  const theme = useTheme();

  const getRanges = useMemo(() => {
    if (!data || isEmpty(data)) return;
    let values: number[] = [];

    data?.forEach((i) => {
      const dataValues = i.data?.map((j) => j.y);
      values = [...values, ...dataValues];
    });

    const maxNumber: number = Math.max(...values);
    const minNumber: number = Math.min(...values);

    const negativeStep: number = Math.abs(minNumber) / 7;
    const positiveStep: number = Math.abs(maxNumber) / 12;

    const zeroColorRange = [
      {
        from: 0,
        to: 0,
        color: "#FFFFFF",
        name: `zero`,
      },
    ];

    const negativeColorsRange = [
      "#E57771",
      "#E7837E",
      "#EA8F8B",
      "#EC9C98",
      "#EEA8A5",
      "#F1B5B1",
      "#F3C1BE",
    ];

    const positiveColorsRange = [
      "#E8DAD8",
      "#FAE6E5",
      "#D0EBD8",
      "#C5E7CE",
      "#BBE2C5",
      "#B0DEBC",
      "#A5D9B3",
      "#9AD5AA",
      "#90D0A1",
      "#85CC98",
      "#7AC78F",
      "#64BE7C",
    ];

    const negativeRange = negativeColorsRange.map((color, index) => ({
      from:
        index === 0
          ? minNumber + index * negativeStep - 0.5
          : minNumber + index * negativeStep,
      to: minNumber + (index + 1) * negativeStep - 0.0001,
      color: color,
      name: `negative-${index + 1}`,
    }));

    const positiveRange = positiveColorsRange.map((color, index) => ({
      from: (index + 0.0001) * positiveStep,
      to:
        index === positiveColorsRange.length - 1
          ? (index + 1) * positiveStep + 0.5
          : (index + 1) * positiveStep,
      color: color,
      name: `positive-${index + 1}`,
    }));

    const combineRanges = [
      ...negativeRange,
      ...zeroColorRange,
      ...positiveRange,
    ];

    const removeDuplicates = combineRanges.filter(
      (range, index, self) =>
        index ===
        self.findIndex((i) => i.from === range.from && i.to === range.to)
    );

    return removeDuplicates;
  }, [data]);

  const getMinMaxValue = useMemo(() => {
    if (!data || isEmpty(data)) return;
    let values: number[] = [];

    data?.forEach((i) => {
      const dataValues = i.data?.map((j) => j.y);
      values = [...values, ...dataValues];
    });

    const maxNumber: number = Math.max(...values);
    const minNumber: number = Math.min(...values);

    return { min: minNumber, max: maxNumber };
  }, [data]);

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: {
        show: false,
      },
      offsetX: 15,
      offsetY: 10,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 1,
    },
    dataLabels: {
      formatter: function (val, opts) {
        return `${val}%`;
      },
      style: {
        fontSize: "10px",
        colors: [theme.palette.grey[900]],
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
        <div class='${styles.linechart_tooltip}' style="background: ${
          //@ts-ignore
          theme.palette.common.solidCard
        }">
          <h6 style="color: ${theme.palette.common.white}">
            ${w.config.series[seriesIndex].name}
          </h6>
          <div>
            <h4 style="color: ${theme.palette.common.white}">SPS Changes:</h4>
            <h5 style="color: ${theme.palette.common.white}">
            ${series[seriesIndex][dataPointIndex]}%
            </h5>
          </div>
        </div>`;
      },
    },
    plotOptions: {
      heatmap: {
        radius: 0,
        enableShades: false,
        shadeIntensity: 0.3,
        reverseNegativeShade: true,
        distributed: false,
        useFillColorAsStroke: true,
        colorScale: {
          min: getMinMaxValue?.min,
          max: getMinMaxValue?.max,
          ranges: data && getRanges,
        },
      },
    },
    xaxis: {
      position: "top",
      labels: {
        rotate: 0,
        style: {
          colors: theme.palette.text.primary,
          fontWeight: 100,
        },
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      reversed: true,
      labels: {
        style: {
          colors: theme.palette.text.primary,
          fontWeight: 100,
        },
      },
    },
  };

  return (
    <>
      {!isEmpty(data) ? (
        <Box display={"flex"} justifyContent={"center"}>
          <Box position={"relative"} width={45}>
            <Box
              sx={{
                position: "absolute",
                top: 52,
                left: 30,
                width: "100%",
                height: 282,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {data?.map((coin) => (
                <Avatar
                  key={coin.id}
                  src={getCoinImage(coin.id)}
                  alt={coin.name}
                  sx={{ width: 20, height: 20 }}
                />
              ))}
            </Box>
          </Box>
          <Box id="heatmap-chart" flex={1}>
            <ReactApexChart
              options={options}
              series={data}
              type="heatmap"
              height={350}
            />
          </Box>
        </Box>
      ) : (
        <Empty text="No Data" />
      )}
    </>
  );
};

export default HeatMap;
