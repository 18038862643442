import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { TimeFrameProps } from "../interfaces";
import { getDatePeriodTooltip } from "../../../utils/functions";
import CustomTooltip from "../../common/customeTooltip";

const TimeFrame: React.FC<TimeFrameProps> = ({
  timePeriod,
  setTimePeriod,
  color,
  timeFrames,
}) => {
  const handleChangeType = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setTimePeriod(newValue);
    }
  };

  return (
    <ToggleButtonGroup value={timePeriod} exclusive onChange={handleChangeType}>
      {timeFrames.map((timeFrame: string) => (
        <ToggleButton key={timeFrame} size="small" value={timeFrame}>
          <CustomTooltip title={getDatePeriodTooltip(timeFrame)}>
            <Typography variant="caption" color={color && color}>
              {timeFrame}
            </Typography>
          </CustomTooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default TimeFrame;
