import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CryptoSignalTab from "./signalTab";
import Loading from "../../common/loading";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import {
  fetchCryptoPageInfluencerSignal,
  setNewsPageData,
} from "../../../features/signal/signalSlice";
import { calculateGuageValue } from "../../../utils/functions";
import styles from "../crypto.module.scss";

const CryptoSignal: React.FC<{
  coinId: number;
  isAvailable: undefined | {};
}> = ({ coinId, isAvailable }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const influencerSignal = useSelector((state: RootState) => state.signal);

  const [page, setPage] = useState<number>(1);
  const [selectedCommunity, setSelectedCommunity] = useState({
    tab: "",
    coin: coinId,
  });

  const fetchNewsDataHandler = (tab: string, coin: number) => {
    const requestProps = {
      coin: coin,
      page: 1,
      category: "",
    };
    dispatch(fetchCryptoPageInfluencerSignal(requestProps)).then((response) => {
      dispatch(
        //@ts-ignore
        setNewsPageData({
          results: response.payload,
          tab: tab,
          coin: coin,
        })
      );
    });
  };

  useEffect(() => {
    if (coinsList.availableCoins)
      fetchNewsDataHandler(selectedCommunity.tab, selectedCommunity.coin);
  }, [coinsList.availableCoins, coinId]);

  useEffect(() => {
    if (influencerSignal.newsPageData) {
      setSelectedCommunity({
        tab: influencerSignal.newsPageData
          ? influencerSignal.newsPageData.tab === ""
            ? ""
            : influencerSignal.newsPageData.tab
          : "influencer",
        coin: influencerSignal.newsPageData
          ? influencerSignal.newsPageData.coin
          : 3,
      });
    }
  }, [influencerSignal.newsPageData]);

  const fetchNextPage = (): void => setPage((prev) => prev + 1);

  useEffect(() => {
    if (page === 1) return;
    const requestProps = {
      coin: selectedCommunity.coin,
      page,
      category: selectedCommunity.tab,
    };
    dispatch(fetchCryptoPageInfluencerSignal(requestProps)).then((response) =>
      dispatch(
        setNewsPageData({
          results: {
            ...influencerSignal.newsPageData?.results,
            relevant_tweets:
              influencerSignal.newsPageData?.results.relevant_tweets?.concat(
                response.payload.relevant_tweets
              ),
          },
          tab: selectedCommunity.tab,
          coin: selectedCommunity.coin,
        })
      )
    );
  }, [page]);

  return (
    <Box mt={1} flexGrow={1}>
      {influencerSignal.cyptoPageInfluencerSignalLoading ? (
        <Loading />
      ) : coinsList.availableCoins && influencerSignal.newsPageData ? (
        <Box className={styles.coinSignalWrapper}>
          <CryptoSignalTab
            guageValue={
              isAvailable !== undefined &&
              influencerSignal.cyptoPageInfluencerSignal &&
              Object.keys(influencerSignal.cyptoPageInfluencerSignal?.signal)
                ?.length > 0
                ? calculateGuageValue(
                    influencerSignal.cyptoPageInfluencerSignal?.signal[0] ?? 0,
                    influencerSignal.cyptoPageInfluencerSignal?.signal[1] ?? 0,
                    influencerSignal.cyptoPageInfluencerSignal?.signal.null ?? 0
                  )
                : 50
            }
            signalCount={influencerSignal.cryptoSignalCount}
            signalCountLoading={influencerSignal.cryptoSignalCountLoading}
            cryptoSignalData={influencerSignal.newsPageData}
            selectedCommunity={selectedCommunity}
            fetchNextPage={fetchNextPage}
            isAvailable={
              !influencerSignal.cyptoPageInfluencerSignal
                ? undefined
                : isAvailable
            }
          />

          {/* {(influencerSignal.newsPageData?.results == undefined ||
            //@ts-ignore
            coinsList.availableCoins.filter(
              (item: { coin_id: number | string }) =>
                //@ts-ignore
                item.coin_id === influencerSignal.newsPageData.coin
            )?.length == 0 ||
            Object.keys(influencerSignal.newsPageData?.results?.signal)
              .length == 0) && (
            <Box
              className={styles.comingSoonWrapper}
              sx={{
                background:
                  theme.palette.mode === "light"
                    ? "rgba(255,255,255,.8)"
                    : "rgba(65,65,65,.8)",
              }}
            >
              <Typography
                variant="h5"
                align="center"
                color={theme.palette.text.primary}
              >
                <strong>N/A</strong> <br /> (Coming Soon)
              </Typography>
            </Box>
          )} */}
        </Box>
      ) : null}
    </Box>
  );
};

export default CryptoSignal;
