import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";

interface InitialAgentBotState {
  CreateBot: {
    coin_name: string[];
    initial_balance: number;
    duration: number;
    rules: {
      sps: { lower: number; upper: number; enabled: boolean };
      technical_score: {
        enabled: boolean;
        buy_upper: number;
        sell_upper: number;
      };
      sentiment_score: {
        enabled: boolean;
        negative_upper: number;
        positive_upper: number;
      };
      price_change: { lower: number; upper: number; enabled: boolean };
    };
    total_portfolio: number;
    trade_percentage: number;
    stop_loss_portfolio_value: number;
    stop_loss_price: number;
  };
  TableData: any[];
  BotDetial: any | null;
}

const initialState: InitialAgentBotState = {
  CreateBot: {
    coin_name: [""],
    initial_balance: 0,
    duration: 0,
    rules: {
      sps: { lower: 0, upper: 0, enabled: false },
      technical_score: { enabled: false, buy_upper: 0, sell_upper: 0 },
      sentiment_score: { enabled: false, negative_upper: 0, positive_upper: 0 },
      price_change: { lower: 0, upper: 0, enabled: false },
    },
    total_portfolio: 0,
    trade_percentage: 0,
    stop_loss_price: 0,
    stop_loss_portfolio_value: 0,
  },
  TableData: [],
  BotDetial: null,
};

export const createBot = createAsyncThunk<
  any,
  void,
  { state: { BotSlice: InitialAgentBotState }; rejectValue: any }
>("BotSlice/createBot", async (_, thunkAPI) => {
  const state = thunkAPI.getState().BotSlice;
  const createBotData = state.CreateBot;

  console.log("CreateBot Data to send:", createBotData);

  try {
    const url = `/agents/create_trade_bot/`;
    console.log("Request URL:", url);
    const response = await http.post(url, createBotData);
    console.log("Response Data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Request Error:", error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInAgentBotDetial = createAsyncThunk<
  any,
  number,
  { rejectValue: any }
>("BotSlice/fetchInAgentBotDetial", async (id, thunkAPI) => {
  try {
    const url = `agents/trade_bot_transactions/?bot_id=${id}`;
    const response = await http.get(url);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInAgentTable = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("BotSlice/fetchInAgentTable", async (_, thunkAPI) => {
  try {
    const url = `agents/create_trade_bot/`;
    const response = await http.get(url);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const BotSlice = createSlice({
  name: "BotSlice",
  initialState,
  reducers: {
    setBotData: (state, action) => {
      const {
        coin_name,
        initial_balance,
        duration,
        rules,
        total_portfolio,
        trade_percentage,
        stop_loss_price,
        stop_loss_portfolio_value,
      } = action.payload;
      state.CreateBot = {
        coin_name,
        initial_balance,
        duration,
        rules,
        total_portfolio,
        trade_percentage,
        stop_loss_price,
        stop_loss_portfolio_value,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInAgentTable.fulfilled, (state, action) => {
      state.TableData = action.payload;
    });
    builder.addCase(createBot.fulfilled, (state, action) => {
      console.log("بات با موفقیت ایجاد شد:", action.payload);
    });
    builder.addCase(fetchInAgentBotDetial.fulfilled, (state, action) => {
      state.BotDetial = action.payload;
    });
  },
});

export const { setBotData } = BotSlice.actions;
export default BotSlice.reducer;
