import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboardLayout";
import { PieAgentBot } from "../../../components/charts/PieAgentBot";
import { useEffect, useState } from "react";
import useAuth from "../../../utils/useAuth";
import { LineBarChartAgent } from "../../../components/charts/LineBarChartAgent";
import { fetchInAgentBotDetial } from "../../../features/agent/agentBotSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TbTableOff } from "react-icons/tb";

interface BotDetail {
  id: number;
  balance: number;
  profit: number;
  loss: number;
  date: string;
}
type ChartData = {
  x: string; // The x-axis value (e.g., "Jan")
  y: number | [number, number]; // A single number (for line) or a range (for rangeBar)
};

type Series = {
  name: string; // Name of the series
  type: "rangeBar" | "line"; // Type of the chart for this series
  data: ChartData[]; // Array of data points
};
export const BotDetailPage = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthValid = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const [chartSeries, setChartSeries] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const botDetial = useSelector((state: any) => state.BotSlice.BotDetial);

  useEffect(() => {
    if (params.id) {
      // @ts-ignore
      dispatch(fetchInAgentBotDetial(Number(params.id)));
    }
  }, [dispatch, params.id]);
  console.log(botDetial);
  useEffect(() => {
    if (botDetial && botDetial.length > 0) {
      const sortedData = [...botDetial].sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      const firstBalance = Number(sortedData[0].initial_balance);
      const currentBalance = Number(sortedData[0].balance);
      const profit =
        currentBalance > firstBalance ? currentBalance - firstBalance : 0;
      const loss =
        currentBalance < firstBalance ? firstBalance - currentBalance : 0;

      setChartSeries([firstBalance, profit, loss]);
      setLoading(false);
    }
  }, [botDetial]);
  const series: Series[] = [
    {
      name: "Temperature Range",
      type: "rangeBar",
      data: [
        { x: "Jan", y: [5, 15] },
        { x: "Feb", y: [10, 20] },
        { x: "Mar", y: [8, 18] },
        { x: "Apr", y: [12, 22] },
        { x: "May", y: [15, 25] },
      ],
    },
    {
      name: "Temperature Range",
      type: "line",
      data: [
        { x: "Jan", y: 1500 },
        { x: "Feb", y: 2000 },
        { x: "Mar", y: 1800 },
        { x: "Apr", y: 2200 },
        { x: "May", y: 2500 },
      ],
    },
  ];
  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={7}>
            <LineBarChartAgent series={series} />
          </Grid>
          <Grid item xs={12} sm={5}>
            {botDetial && botDetial.length ? (
              <PieAgentBot
                series={chartSeries}
                labels={["FirstBalance", "Profit", "Loss"]}
                isNull={false}
              />
            ) : (
              <PieAgentBot
                series={chartSeries}
                labels={["FirstBalance", "Profit", "Loss"]}
                isNull={true}
              />
            )}
          </Grid>
        </Grid>
        <Box sx={{ width: downLg ? "300px" : "100%" }}>
          {botDetial && botDetial.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}>Id</TableCell>
                    <TableCell align={"center"}>Status</TableCell>
                    <TableCell align={"center"}>InitialBalance</TableCell>
                    <TableCell align={"center"}>Balance</TableCell>
                    <TableCell align={"center"}>Profit</TableCell>
                    <TableCell align={"center"}>Loss</TableCell>
                    <TableCell align={"center"}>Created Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...botDetial].reverse().map((row: any, index: number) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        ":hover": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? theme.palette.primary.light
                              : theme.palette.primary.main,
                        },
                      }}
                    >
                      <TableCell align={"center"} component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align={"center"} component="th" scope="row">
                        {row.action}
                      </TableCell>
                      <TableCell align={"center"}>
                        {row.initial_balance}
                      </TableCell>
                      <TableCell align={"center"}>{row.balance}</TableCell>
                      <TableCell align={"center"}>
                        {row.initial_balance < row.balance
                          ? row.initial_balance + row.balance
                          : 0}
                      </TableCell>
                      <TableCell align={"center"}>
                        {row.initial_balance > row.balance
                          ? row.initial_balance - row.balance
                          : 0}
                      </TableCell>
                      <TableCell align={"center"}>
                        {moment(row.created_at).format("YYYY-MM-DD  HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper} sx={{ height: 250 }}>
              <Table
                sx={{ minWidth: 250, maxHeight: 250, height: 250 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}>Id</TableCell>
                    <TableCell align={"center"}>Status</TableCell>
                    <TableCell align={"center"}>InitialBalance</TableCell>
                    <TableCell align={"center"}>Balance</TableCell>
                    <TableCell align={"center"}>Profit</TableCell>
                    <TableCell align={"center"}>Loss</TableCell>
                    <TableCell align={"center"}>Created Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ textAlign: "center", height: "100%" }}>
                  <TableRow sx={{ textAlign: "center", height: "100%" }}>
                    <TableCell
                      colSpan={7}
                      sx={{ textAlign: "center", height: "100%" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography variant="h6">Empty!</Typography>
                        <TbTableOff size={48} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};
