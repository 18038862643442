import { Box, Typography, useTheme } from "@mui/material";
import { HiOutlineDocumentText } from "react-icons/hi";
import { RiUserFollowFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { InfluencerStatisticsHeaderProps } from "../interfaces";
import InfluencerTitle from "../../common/title/InfluencerTitle";
import styles from "../influencer.module.scss";

const AccountStatistics: React.FC<InfluencerStatisticsHeaderProps> = ({
  data,
}) => {
  const theme = useTheme();

  return (
    <Box
      className={styles.accountStatisticsWrapper}
      sx={{
        borderRadius: "8px",
        overflow: "hidden",
        background: theme.palette.primary.light,
        border: (theme: any) => `1px solid ${theme.palette.common.darkPrimary}`,
      }}
    >
      <InfluencerTitle title="Account Statistics" />

      <Box className={styles.accountStatistics}>
        <Box className={styles.accountStatistics_card}>
          <Box>
            <Typography
              variant="h5"
              //@ts-ignore
              color={theme.palette.primary.main}
            >
              {data.results.twitter_user?.statuses_count?.toLocaleString()}
            </Typography>
            <Typography
              variant="body1"
              component="h6"
              color={theme.palette.text.primary}
            >
              Posts
            </Typography>
          </Box>
          <Box>
            <HiOutlineDocumentText
              size={30}
              color={
                theme.palette.mode === "dark"
                  ? theme.palette.text.primary
                  : theme.palette.common.black
              }
            />
          </Box>
        </Box>
        <Box className={styles.accountStatistics_card}>
          <Box>
            <Typography
              variant="h5"
              //@ts-ignore
              color={theme.palette.primary.main}
            >
              {data.results.twitter_user?.followers_count?.toLocaleString()}
            </Typography>
            <Typography
              variant="body1"
              component="h6"
              color={theme.palette.text.primary}
            >
              Followers
            </Typography>
          </Box>
          <Box>
            <FaUsers
              size={30}
              color={
                theme.palette.mode === "dark"
                  ? theme.palette.text.primary
                  : theme.palette.common.black
              }
            />
          </Box>
        </Box>
        <Box className={styles.accountStatistics_card}>
          <Box>
            <Typography
              variant="h5"
              //@ts-ignore
              color={theme.palette.primary.main}
            >
              {data.results.twitter_user?.friends_count?.toLocaleString()}
            </Typography>
            <Typography
              variant="body1"
              component="h6"
              color={theme.palette.text.primary}
            >
              Following
            </Typography>
          </Box>
          <Box>
            <RiUserFollowFill
              size={30}
              color={
                theme.palette.mode === "dark"
                  ? theme.palette.text.primary
                  : theme.palette.common.black
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountStatistics;
