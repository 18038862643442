import { Badge, Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Avatar from "react-avatar";
import { useRef, useState } from "react";
import DefaultModal from "../../common/modal";
import { styled } from "@mui/material/styles";
import { MdCloudUpload, MdDeleteForever, MdEdit } from "react-icons/md";
import { useAppDispatch } from "../../../features/store";
import { FaUserCircle } from "react-icons/fa";
import {
  deleteAvatar,
  uploadAvatar,
} from "../../../features/profile/profiletSlice";
import AvatarEditor from "react-avatar-editor";

const UserAvatar = Avatar;
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const AccountHeader = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState("");
  const [avatarLoading, setAvatarLoading] = useState(false);
  const previewRef = useRef<string | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);

  const handleOpen = () => setOpen(true);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        previewRef.current = reader.result as string;
        setPreview("");
      };
      reader.readAsDataURL(file);
      setAvatarLoading(true);
      await dispatch(uploadAvatar(file));
      setAvatarLoading(false);
    }
  };

  const handleDelete = async () => {
    if (preview === "") {
      setAvatarLoading(true);
      await dispatch(deleteAvatar());
      setAvatarLoading(false);
      setOpen(false);
      window.location.reload();
    } else {
      setPreview("");
    }
  };

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas(); // دریافت Canvas ویرایش‌شده
      canvas.toBlob(async (blob) => {
        if (blob) {
          // تبدیل Blob به File
          const file = new File([blob], "avatar.jpg", { type: "image/jpeg" });

          setAvatarLoading(true);
          await dispatch(uploadAvatar(file)); // ارسال فایل به سرور
          setAvatarLoading(false);
          setOpen(false);
          window.location.reload(); // تازه‌سازی صفحه
        }
      }, "image/jpeg");
    }
  };

  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem("UserData"));
  console.log(userData);

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: "50%",
              boxShadow: theme.shadows[2],
              padding: "4px",
            }}
          >
            <MdEdit size={16} color={theme.palette.text.primary} />
          </Box>
        }
        onClick={handleOpen}
        sx={{ cursor: "pointer" }}
      >
        <Box
          sx={{
            width: "90px",
            height: "90px",
            borderRadius: "50%",
            overflow: "hidden",
            marginTop: "16px",
            marginBottom: 0,
          }}
        >
          {previewRef.current ||
          userData?.profile_picture ||
          userData?.profile_picture_google ? (
            <UserAvatar
              src={
                previewRef.current
                  ? previewRef.current
                  : userData?.profile_picture
                    ? userData?.profile_picture
                    : userData?.profile_picture_google
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.main
                    : theme.palette.grey["700"],
              }}
            />
          ) : (
            <FaUserCircle
              style={{
                width: "100%",
                height: "100%",
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.main
                    : theme.palette.grey["700"],
              }}
            />
          )}
        </Box>
      </Badge>

      <Typography
        variant="body2"
        component="h1"
        color={theme.palette.text.primary}
        sx={{
          lineHeight: 1,
          marginTop: ".5rem !important",
        }}
      >
        {!userData ? (
          <BiDotsHorizontalRounded style={{ margin: 0 }} />
        ) : (
          `${userData?.first_name} ${userData?.last_name}`
        )}
      </Typography>

      <DefaultModal
        open={open}
        title="Update Avatar"
        setOpen={setOpen}
        content={
          <Box>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              width={"100%"}
            >
              <Grid item xs={12}>
                {previewRef.current ? (
                  <AvatarEditor
                    image={previewRef.current}
                    ref={editorRef}
                    width={250}
                    height={250}
                    border={20}
                    borderRadius={200}
                    scale={1.2}
                    crossOrigin="anonymous"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      marginTop: "16px",
                      marginBottom: 0,
                    }}
                  >
                    {userData?.profile_picture ||
                    userData?.profile_picture_google ? (
                      <img
                        src={
                          userData?.profile_picture
                            ? userData?.profile_picture
                            : userData?.profile_picture_google
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          color:
                            theme.palette.mode === "light"
                              ? theme.palette.primary.main
                              : theme.palette.grey["700"],
                        }}
                      />
                    ) : (
                      <FaUserCircle
                        style={{
                          width: "100%",
                          height: "100%",
                          color:
                            theme.palette.mode === "light"
                              ? theme.palette.primary.main
                              : theme.palette.grey["700"],
                        }}
                      />
                    )}
                  </Box>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                width={"100%"}
                display="flex"
                flexDirection={"row"}
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={6}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<MdCloudUpload />}
                    disabled={avatarLoading}
                  >
                    {avatarLoading ? "Uploading..." : "Upload Image"}
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </Button>
                </Grid>
                {(userData?.profile_picture ||
                  userData?.profile_picture_google ||
                  preview) && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      sx={{ paddingX: "40px" }}
                      color={"error"}
                      variant="outlined"
                      startIcon={<MdDeleteForever />}
                      onClick={handleDelete}
                    >
                      {avatarLoading ? "Deleting..." : "Delete"}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Box>
        }
      />
    </>
  );
};
