import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { PerformanceProps } from "../../interfaces";
import Loading from "../../../common/loading";
import { LuInfo } from "react-icons/lu";
import DefaultTooltip from "../../../common/tooltip";

const CoinPerformance: React.FC<PerformanceProps> = ({ data, size }) => {
  const theme = useTheme();
  const [performanceData, setPerformanceData] = useState<any[]>([]);

  useEffect(() => {
    if (data.coinPerformance)
      setPerformanceData([
        {
          name: "1D",
          dollar_change: data.coinPerformance?.one_day?.dollar_change,
          percentage_change: data.coinPerformance?.one_day?.percentage_change,
        },
        {
          name: "1W",
          dollar_change: data.coinPerformance?.one_week?.dollar_change,
          percentage_change: data.coinPerformance?.one_week?.percentage_change,
        },
        {
          name: "1M",
          dollar_change: data.coinPerformance?.one_month?.dollar_change,
          percentage_change: data.coinPerformance?.one_month?.percentage_change,
        },
        {
          name: "2M",
          dollar_change: data.coinPerformance?.two_month?.dollar_change,
          percentage_change: data.coinPerformance?.two_month?.percentage_change,
        },
        {
          name: "3M",
          dollar_change: data.coinPerformance?.three_month?.dollar_change,
          percentage_change:
            data.coinPerformance?.three_month?.percentage_change,
        },
        {
          name: "6M",
          dollar_change: data.coinPerformance?.six_month?.dollar_change,
          percentage_change: data.coinPerformance?.six_month?.percentage_change,
        },
        // {
        //   name: "1Y",
        //   dollar_change: data.coinPerformance?.one_year?.dollar_change,
        //   percentage_change: data.coinPerformance?.one_year?.percentage_change,
        // },
      ]);
  }, [data.coinPerformance]);

  return (
    <>
      <Typography
        mt={5}
        mb={2}
        align="center"
        color={theme.palette.text.primary}
      >
        Performance
        {/* <DefaultTooltip title="info">
          <span style={{ marginLeft: 4 }}>
            <LuInfo />
          </span>
        </DefaultTooltip> */}
      </Typography>

      {data.coinPerformanceLoading ? (
        <Loading />
      ) : performanceData.length > 0 ? (
        <Grid
          container
          sx={{
            py: size == "small" ? 0 : 2,
            mt: size == "small" ? 0 : 2,
          }}
        >
          {performanceData.map((dataItem: any) => (
            <Grid
              item
              xs={size == "small" ? 6 : 12}
              lg={4}
              key={dataItem.name}
              sx={{ padding: ".2rem" }}
            >
              <Box
                sx={{
                  background: theme.palette.background.paper,
                  borderRadius: "8px",
                  padding: ".8rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 900 }}>
                  {dataItem.name}
                </Typography>

                {dataItem.percentage_change && dataItem.dollar_change ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      color={
                        dataItem.percentage_change < 0
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                    >
                      {dataItem.percentage_change < 0 ? "-" : "+"}%
                      {Math.abs(dataItem.percentage_change).toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={(theme: any) => theme.palette.common.mute}
                    >
                      {dataItem.dollar_change < 0 ? "-" : "+"}$
                      {Math.abs(
                        dataItem.dollar_change.toFixed(2)
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      color={(theme: any) => theme.palette.common.mute}
                    >
                      N/A
                    </Typography>
                    <Typography
                      variant="body2"
                      color={(theme: any) => theme.palette.common.mute}
                    >
                      N/A
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          className={`default-card-style `}
          sx={{ background: theme.palette.background.paper, width: "100%" }}
        >
          <Typography align="center" py={3}>
            No data
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CoinPerformance;
