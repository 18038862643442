import ApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMediaQuery, useTheme } from "@mui/material";

type PieAgentBotProps = {
  series: number[]; // [firstBalance, profit, loss]
  labels: string[];
  isNull: boolean; // Add isNull as a prop
};

export const PieAgentBot = ({ series, labels, isNull }: PieAgentBotProps) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  // Handle the series and labels when isNull is true
  const displayedSeries = isNull ? [1] : series; // If isNull, show a dummy value
  const displayedLabels = isNull ? ["Null"] : labels; // If isNull, show "Null" label

  const chartOption: ApexOptions = {
    chart: {
      type: "donut",
    },
    labels: displayedLabels, // Use displayed labels

    plotOptions: {
      pie: {
        donut: {
          size: "60%", // Adjust this percentage for the size of the donut hole
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Arial",
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Arial",
              color: "white",
              offsetY: 10,
              formatter: (val) => `${val}`, // Formats the value (e.g., "300")
            },
            total: {
              show: true,
              label: isNull ? "No Data" : "Total", // Show "No Data" if isNull
              formatter: (w) => {
                if (isNull) return "N/A"; // Display "N/A" when isNull
                // Check if series is available
                const firstBalance = w.config.series[0]; // firstBalance
                const profit = w.config.series[1]; // profit
                const loss = w.config.series[2]; // loss

                if (profit > 0) {
                  return firstBalance + profit; // Add profit to firstBalance
                } else if (loss > 0) {
                  return firstBalance - loss; // Subtract loss from firstBalance
                }

                return firstBalance; // Default to firstBalance if no profit/loss
              },
            },
          },
        },
        customScale: 1,
      },
    },
    colors: isNull ? ["#bdc3c7"] : ["#3498db", "#2ecc71", "#e74c3c"], // Gray color for Null state
  };

  return (
    <ApexChart
      width={downLg ? 350 : 400}
      height={downLg ? 350 : 400}
      options={chartOption}
      series={displayedSeries}
      type="donut"
    />
  );
};
