import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { RiPushpin2Fill } from "react-icons/ri";
import { MdOutlineChevronLeft } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { PiPushPinSimpleSlashFill } from "react-icons/pi";
import BottomDrawer from "../../common/bottomDrawer";
import FullPageBlur from "../../common/fullPageBlur";
import Share from "../../common/share";
import Avatar from "../../common/avatar";
import { getUserBanner } from "../../../utils/userPhoto";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { fetchPinnedInfluencers } from "../../../features/influencers/influencersSlice";
import { pinInfluencer, removeInfluencerPin } from "../../../api/influencers";
import { InfluencerHeaderProps } from "../interfaces";
import LoginAlertModal from "../../common/loginAlertModal";
import styles from "../influencer.module.scss";

const InfluencerHeader: React.FC<InfluencerHeaderProps> = ({ data }) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const account = useSelector((state: RootState) => state.account);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const influencersList: any = useSelector(
    (state: RootState) => state.influencers
  );

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);

  let isPinned = influencersList.pinnedInfluencers?.results.findIndex(
    (inf: { id_str: number | string }) => inf.id_str === data.id_str
  );

  useEffect(() => {
    if (cryptosState.availableCoins && !influencersList.pinnedInfluencers) {
      dispatch(fetchPinnedInfluencers());
    }
  }, [cryptosState.availableCoins]);

  const pinUserHandler = () => {
    if (account.isAuthenticated) {
      setLoading(true);
      pinInfluencer(data.id_str)
        .then((response) => {
          setLoading(false);
          if (response.status === 201) {
            dispatch(fetchPinnedInfluencers());
            toast.success("Influencer added to your pin list");
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoginAlertModal(true);
    }
  };

  const unpinUserHandler = () => {
    setLoading(true);
    removeInfluencerPin(data.id_str)
      .then((response) => {
        setLoading(false);
        if (response.status === 204) {
          dispatch(fetchPinnedInfluencers());
          toast.success("Influencer removed from your pin list");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <BottomDrawer open={open} setOpen={setOpen}>
        <Box className={styles.shareContainer}>
          <Share
            facebookUrl={`https://twitter.com/${data.screen_name}`}
            telegramUrl={`https://twitter.com/${data.screen_name}`}
            gmailUrl={`https://twitter.com/${data.screen_name}`}
            twitterUrl={`https://twitter.com/${data.screen_name}`}
          />
          <Typography
            variant="body2"
            align="center"
            pt={2}
            color={theme.palette.text.primary}
          >
            Share this profile with the social media users
          </Typography>
        </Box>
      </BottomDrawer>
      <FullPageBlur isVisible={open} setIsVisible={setOpen} />

      <Box className={styles.breadcrumb}>
        <Link to="/influencers" style={{ color: theme.palette.warning.main }}>
          <MdOutlineChevronLeft size={25} /> Influencers
        </Link>
      </Box>
      <Box
        className={styles.influencerInfo}
        sx={{
          background:
            theme.palette.mode === "light"
              ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 26.03%, #fff 100%),
              url(${getUserBanner(data.screen_name)}), ${
                //@ts-ignore
                theme.palette.primary.main
              } 50% / cover no-repeat`
              : `linear-gradient(180deg, rgba(0, 0, 0, 0) 26.03%, #000 78.08%),
              url(${getUserBanner(data.screen_name)}), ${
                //@ts-ignore
                theme.palette.primary.main
              } 50% / cover no-repeat`,
        }}
      >
        <Box className={styles.influencerInfoContent}>
          <Avatar screenName={data.screen_name} width={65} height={65} />
          <Box sx={{ marginLeft: "8px" }}>
            <Typography
              variant="h6"
              component="h1"
              sx={{ color: theme.palette.text.primary }}
            >
              {data.name}
            </Typography>
            <Typography
              variant="caption"
              component="h2"
              sx={{ color: theme.palette.text.primary }}
            >
              @{data.screen_name}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.influencerInfoActions}>
          {isPinned > -1 ? (
            <IconButton
              aria-label="Pin"
              size="small"
              onClick={unpinUserHandler}
              disabled={
                loading || influencersList.pinnedInfluencersLoading
                  ? true
                  : false
              }
              sx={{
                opacity:
                  loading || influencersList.pinnedInfluencersLoading ? 0.5 : 1,
              }}
            >
              <PiPushPinSimpleSlashFill color={theme.palette.text.primary} />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Pin"
              size="small"
              onClick={pinUserHandler}
              disabled={
                loading || influencersList.pinnedInfluencersLoading
                  ? true
                  : false
              }
              sx={{
                opacity:
                  loading || influencersList.pinnedInfluencersLoading ? 0.5 : 1,
              }}
            >
              <RiPushpin2Fill color={theme.palette.text.primary} />
            </IconButton>
          )}
          <a href={`https://twitter.com/${data.screen_name}`} target="_blank">
            <IconButton aria-label="XTwitter" size="small">
              <FaXTwitter color={theme.palette.text.primary} />
            </IconButton>
          </a>
          <IconButton
            aria-label="Share"
            size="small"
            onClick={() => setOpen(true)}
          >
            <IoShareSocialOutline color={theme.palette.text.primary} />
          </IconButton>
        </Box>
      </Box>
      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </>
  );
};

export default InfluencerHeader;
